import {
  Form,
  Typography,
  Image,
  Radio,
  Checkbox,
  Input,
  InputNumber,
  ConfigProvider,
  DatePicker,
} from 'antd';
import { Rule } from 'antd/lib/form';
import React, { CSSProperties, ReactElement, useEffect, useState, useRef } from 'react';
import { PrimaryButton, SecondaryButton } from '../../components/button';
import {
  QuestionProps,
  InputTypeQuestionnaire,
  SelectTypeQuestionnaire,
  QuestionsProps,
} from '../../services/questionnaire/types';
import { Color, DATE_FORMAT, KanitFont, NO_IMAGE } from '../../constants';
import locale from 'antd/lib/locale/th_TH';
import './questionnaire.css';

const { Item } = Form;
const { Title } = Typography;
const screenHeight = window.innerHeight;

enum suffixLable {
  single = '(กรุณาเลือก)',
  multiple = '(เลือกได้มากกว่า 1 ข้อ)',
}

interface CustomQuestionProps extends QuestionProps {
  rules?: Array<Rule>;
}

const labelFormat = (name: string, type?: SelectTypeQuestionnaire | string) => {
  switch (type) {
    case SelectTypeQuestionnaire.singleSelected:
      return (
        <span>
          {name}
          <br />
          {suffixLable.single}
        </span>
      );
    case SelectTypeQuestionnaire.multipleSelected:
      return (
        <span>
          {name}
          <br />
          {suffixLable.multiple}
        </span>
      );
    default:
      return name;
  }
};

const SingleSelected: React.FC<QuestionProps> = (props): ReactElement => {
  const { field_name, field_options } = props;
  const sum = (acc: number, current: number) => acc + current;
  const countSubType = field_options!
    .map((field) => (field.sup_type ? 1 : 0))
    .reduce(sum, 0);
  const isMultipleRadio =
    field_options!.length - countSubType > 2 ? true : false;
  const rule = [
    {
      required: true,
      message: 'โปรดระบุ !',
    },
  ];
  return (
    <>
      <Item name={field_name} rules={rule}>
        {field_options &&
          (isMultipleRadio ? (
            <Radio.Group style={styles.checkboxGroup}>
              {field_options!.map((field, i) => {
                return (
                  <Radio
                    value={field.name}
                    key={`radio-id-${i}`}
                    style={styles.checkbox}
                  >
                    {field.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          ) : (
              <Radio.Group style={styles.rowStyle}>
                {field_options!.map((field, i) => {
                  return (
                    <Radio.Button
                      value={field.name}
                      key={`radio-id-${i}`}
                      style={styles.buttonRadio}
                    >
                      {field.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            ))}
      </Item>
    </>
  );
};

const MultipleSelected: React.FC<QuestionProps> = (props): ReactElement => {
  const { field_name, field_options } = props;
  return (
    <>
      <Item>
        <div style={styles.checkboxGroup}>
          <Item name={field_name}>
            <Checkbox.Group>
              {field_options &&
                field_options.map((field, i) => {
                  return (
                    <div key={`check-box-id-${i}`}>
                      <Checkbox value={field.name} style={styles.checkbox}>
                        {field.label}
                      </Checkbox>
                      <br />
                    </div>
                  );
                })}
            </Checkbox.Group>
          </Item>
        </div>
      </Item>
    </>
  );
};

const Field: React.FC<CustomQuestionProps> = (props): ReactElement => {
  const {
    field_name,
    field_max,
    field_max_length,
    field_min,
    field_step,
  } = props;
  const rules = props.rules ? props.rules : undefined;
  switch (props.type) {
    case SelectTypeQuestionnaire.singleSelected:
      return <SingleSelected {...props} />;

    case SelectTypeQuestionnaire.multipleSelected:
      return <MultipleSelected {...props} />;

    case InputTypeQuestionnaire.dateTime:
      return (
        <>
          <Item name={field_name} rules={rules}>
            <DatePicker
              style={styles.input}
              format={DATE_FORMAT}
              placeholder={'เลือกวันที่'}
              inputReadOnly={true}
            />
          </Item>
        </>
      );

    case InputTypeQuestionnaire.text:
      return (
        <>
          <Item name={field_name} rules={rules}>
            <Input style={styles.input} maxLength={field_max_length} />
          </Item>
        </>
      );

    case InputTypeQuestionnaire.number:
      const numberRule: Array<Rule> = [
        ...rules!,
        {
          type: 'number',
          message: 'กรอกได้เฉพาะตัวเลข !',
        },
      ];
      return (
        <>
          <Item name={field_name} rules={numberRule}>
            <InputNumber
              type="number"
              style={{ ...styles.input, paddingLeft: 6 }}
              min={field_min ? field_min : 0}
              max={field_max ? field_max : Number.MAX_SAFE_INTEGER}
              step={field_step ? field_step : 1}
            />
          </Item>
        </>
      );

    default:
      return (
        <Item>
          <Title level={5}>{props.name}</Title>
        </Item>
      );
  }
};

const expandableStyle = {
  collapes: {
    overflow: 'hidden',
    maxHeight: screenHeight < 670 ? 90 : null,
    marginTop: 20
  },
  expanded: {
    overflow: 'visiable',
  },
}

interface ExpandableProps {
  html: string;
}

const Expandable: React.FC<ExpandableProps> = ({ html }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const contentContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentContainer.current == null) { return }
    const isOverflow = contentContainer.current.offsetHeight < contentContainer.current.scrollHeight
    setIsOverflow(isOverflow);
  }, [contentContainer.current]);

  return (
    <div>
      <div
        ref={contentContainer}
        style={(isExpanded ? expandableStyle.expanded : expandableStyle.collapes)}
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={() => setIsExpanded(!isExpanded)}>  
      </div>
      {isOverflow
        ? 
        (<div style={{marginTop: 10}}>
        <a onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'ซ่อน' : 'อ่านต่อ'}
          </a>
          </div>)

        : null}
    </div>
  )
}

const Questionnaire: React.FC<QuestionsProps> = ({
  questions,
  initialValue,
  setFooter,
  ...props
}) => {
  const [form] = Form.useForm();
  const onNext = () => { 
    form.submit();
  };
  const image = questions[0].image;
  const initialValueObject: Object = questions.reduce((fields, field) => {
    return {
      ...fields,
      [field.field_name]: initialValue!.get(field.field_name),
    };
  }, {});
  const rules: Array<Rule> = [
    {
      required: true,
      message: 'โปรดระบุ !',
    },
  ];
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setFooter &&
      setFooter(
        <div style={styles.buttonBox}>
          <SecondaryButton
            style={{ ...styles.button, ...styles.buttonLeft }}
            title="ย้อนกลับ"
            onClick={props.backHandler}
          />
          <PrimaryButton
            style={{ ...styles.button, ...styles.buttonRight }}
            title="ถัดไป"
            onClick={onNext}
            disabled={isError}
          />
        </div>
      );
  }, [isError]);
  return (
    <div style={styles.container} className="question-page">
      <Form
        key={`questions_${props.id}`}
        name={`questions_${props.id}`}
        form={form}
        initialValues={initialValueObject}
        layout={'vertical'}
        onFieldsChange={(fieldsValue) => setIsError(!!fieldsValue.filter(({errors}) => errors!.length).length)}
      >
        <Item>
          <div style={styles.image}>
            <Image width={158} height={150} src={image} fallback={NO_IMAGE} />
          </div>
        </Item>
        {questions.map((question, i) => {
          const detail = question.detail ? question.detail : '';
          return (
            <div key={`field-id-${i}`}>
              <Title level={4} style={styles.title}>
                {labelFormat(question.name, question.type)}
              </Title>
              <div style={{marginBottom:10}} >
               <Expandable html={detail}/>
              </div>
              <ConfigProvider locale={locale}>
                <Field
                  {...question}
                  rules={question.type !== SelectTypeQuestionnaire.multipleSelected
                    ? rules
                    : undefined
                  }
                />
              </ConfigProvider>
            </div>
          );
        })}
      </Form>
    </div>
  );
};

export interface StylesDictionary {
  [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
  },
  bg: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  textArea: {
    flex: 1,
    flexGrow: 1,
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  button: {
    marginTop: 8,
    height: 40,
    width: '100%',
  },
  buttonLeft: {
    marginRight: 12,
  },
  buttonRight: {
    marginLeft: 12,
  },
  buttonRadio: {
    width: 136,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 15,
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    marginBottom: 10,
  },
  columnStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  input: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'left',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.pictonBlue,
    borderRadius: 4,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    color: Color.pictonBlue,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Color.white,
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    fontFamily: KanitFont,
    fontWeight: 'bold',
    fontSize: 16,
    color: Color.eclipse,
    marginBottom: 16,
  },
};

export default Questionnaire;
