export const GET_PROVINCES_PENDING = "GET_PROVINCES_PENDING";
export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_PROVINCES_FAILED = "GET_PROVINCES_FAILED";
export const GET_DISTRICTS_PENDING = "GET_DISTRICTS_PENDING";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const GET_DISTRICTS_FAILED = "GET_DISTRICTS_FAILED";
export const GET_SUBDISTRICTS_PENDING = "GET_SUBDISTRICTS_PENDING";
export const GET_SUBDISTRICTS_SUCCESS = "GET_SUBDISTRICTS_SUCCESS";
export const GET_SUBDISTRICTS_FAILED = "GET_SUBDISTRICTS_FAILED";

export interface AdministrativeState {
  errorMessage: string,
  address: AddressState,
  isGettingDistricts: boolean,
  isGettingProvinces: boolean,
  isGettingSubdistricts: boolean,
}


export interface AddressState {
  districts: Array<AddressInfo>,
  provinces: Array<AddressInfo>,
  subdistricts: Array<AddressInfo>,
}

export interface AddressInfo {
  id: string,
  name: string,
}

export interface Error {
  errorMessage: string,
}

export interface GetProvincesPendingAction {
  type: typeof GET_PROVINCES_PENDING,
}

export interface GetProvincesSuccessAction {
  type: typeof GET_PROVINCES_SUCCESS,
  provinces: Array<AddressInfo>,
}

export interface GetProvincesFailedAction {
  type: typeof GET_PROVINCES_FAILED,
  error: string,
}

export interface GetDistrictsPendingAction {
  type: typeof GET_DISTRICTS_PENDING,
}

export interface GetDistrictsSuccessAction {
  type: typeof GET_DISTRICTS_SUCCESS,
  districts: Array<AddressInfo>,
}

export interface GetDistrictsFailedAction {
  type: typeof GET_DISTRICTS_FAILED,
  error: string,
}

export interface GetSubdistrictsPendingAction {
  type: typeof GET_SUBDISTRICTS_PENDING,
}

export interface GetSubdistrictsSuccessAction {
  type: typeof GET_SUBDISTRICTS_SUCCESS,
  subdistricts: Array<AddressInfo>,
}

export interface GetSubdistrictsFailedAction {
  type: typeof GET_SUBDISTRICTS_FAILED,
  error: string,
}

export type AdministrativeActionType =
  | GetProvincesPendingAction | GetProvincesSuccessAction | GetProvincesFailedAction 
  | GetDistrictsPendingAction | GetDistrictsSuccessAction | GetDistrictsFailedAction
  | GetSubdistrictsPendingAction | GetSubdistrictsSuccessAction | GetSubdistrictsFailedAction;
