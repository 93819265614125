import {
    AppointmentActionType,
    AppointmentState,
    CREATE_APPOINTMENT_FAILED,
    CREATE_APPOINTMENT_PENDING,
    CREATE_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAILED,
    GET_APPOINTMENT_PENDING,
    GET_APPOINTMENT_SUCCESS,
    GET_AVAILABLE_VENUES_FAILED,
    GET_AVAILABLE_VENUES_PENDING,
    GET_AVAILABLE_VENUES_SUCCESS,
    GET_AVAILBLE_DATES_FAILED,
    GET_AVAILBLE_DATES_PENDING,
    GET_AVAILBLE_DATES_SUCCESS,
    PRINT_APPOINTMENT_PENDING,
    PRINT_APPOINTMENT_SUCCESS,
    PRINT_APPOINTMENT_FAILED,
    GET_LIST_APPOINTMENT_PENDING,
    GET_LIST_APPOINTMENT_FAILED,
    GET_LIST_APPOINTMENT_SUCCESS,
    CANCEL_APPOINTMENT_PENDING,
    CANCEL_APPOINTMENT_SUCCESS,
    CANCEL_APPOINTMENT_FAILED,
    SET_BOOK_QUEUE,
    CLEAR_BOOK_QUEUE,
    CLEAR_ERROR,
} from './types';

const initialState: AppointmentState = {
    appointment: {
        id: '',
        appointment_no: '',
        url: '',
        venue: '',
        when: '',
        print_url: '',
        status:'',
    },
    listAppointment: [],
    bookQueue: {
        date: '',
        timeRange: '',
        venue: '',
    },
    dates: {},
    venues: [],
    isCreatingAppointment: false,
    isGettingDate: false,
    isGettingVenues: false,
    isGettingAppointment: false,
    isGettingListAppointment: false,
    isPrintAppointment: false,
    isCancelAppointment: false,
    error: '',
    errorMessage: '',
}

export const AppointmentReducer = (state = initialState, action: AppointmentActionType): AppointmentState => {
    switch (action.type) {
        case GET_AVAILABLE_VENUES_PENDING:
            return {
                ...state,
                isGettingVenues: true,
            }
        case GET_AVAILABLE_VENUES_SUCCESS:
            return {
                ...state,
                isGettingVenues: false,
                venues: action.venues,
            }
        case GET_AVAILABLE_VENUES_FAILED:
            return {
                ...state,
                isGettingVenues: false,
                error: action.error,
            }
        case GET_AVAILBLE_DATES_PENDING:
            return {
                ...state,
                isGettingDate: true,
            }
        case GET_AVAILBLE_DATES_SUCCESS:
            return {
                ...state,
                isGettingDate: false,
                dates: action.dates,
            }
        case GET_AVAILBLE_DATES_FAILED:
            return {
                ...state,
                isGettingDate: false,
                error: action.error,
            }
        case SET_BOOK_QUEUE:
            return {
                ...state,
                bookQueue: action.bookQueue,
            }
        case CREATE_APPOINTMENT_PENDING:
            return {
                ...state,
                isCreatingAppointment: true,
                error: '',
            }
        case CREATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isCreatingAppointment: false,
                appointment: action.appointment,
            }
        case CREATE_APPOINTMENT_FAILED:
            return {
                ...state,
                isCreatingAppointment: false,
                error: action.error,
            }
        case GET_APPOINTMENT_PENDING:
            return {
                ...state,
                isGettingAppointment: true,
                error: '',
            }
        case GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isGettingAppointment: false,
                appointment: action.appointment,
            }
        case GET_APPOINTMENT_FAILED:
            return {
                ...state,
                isGettingAppointment: false,
                error: action.error,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: '',
            }
        case CLEAR_BOOK_QUEUE:
            return {
                ...state,
                bookQueue: {
                    date: '',
                    timeRange: '',
                    venue: '',
                },
            }
        case PRINT_APPOINTMENT_PENDING:
            return {
                ...state,
                isPrintAppointment: true,
                error: '',
            }
        case PRINT_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isPrintAppointment: false,
                appointment: action.appointment,
            }
        case PRINT_APPOINTMENT_FAILED:
            return {
                ...state,
                isPrintAppointment: false,
                error: action.error,
            }
        case GET_LIST_APPOINTMENT_PENDING:
            return {
                ...state,
                isGettingListAppointment: true,
                error: '',
            }
        case GET_LIST_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isGettingListAppointment: false,
                listAppointment: action.listAppointment,
            }
        case GET_LIST_APPOINTMENT_FAILED:
            return {
                ...state,
                isGettingListAppointment: false,
                error: action.error,
            }
        case CANCEL_APPOINTMENT_PENDING:
            return {
                ...state,
                isCancelAppointment: true,
                error: '',
            }
        case CANCEL_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isCancelAppointment: false,
                appointment: action.appointment,
            }
        case CANCEL_APPOINTMENT_FAILED:
            return {
                ...state,
                isCancelAppointment: false,
                errorMessage: action.errorMessage,
            }
        default:
            return state
    }
}
