import axios, { AxiosError } from 'axios';
import { AppThunk } from '..';
import { API, url } from '../../constants';
import {
  AppError,
  LIST_QUESTIONNAIRE_FAIL,
  LIST_QUESTIONNAIRE_PENDING,
  LIST_QUESTIONNAIRE_SUCCESS,
  Questionnaire,
  QuestionnaireActionType,
  GET_RISK_FAIL,
  GET_RISK_PENDING,
  GET_RISK_SUCCESS,
  CLEAR_DATA_RISK,
  DataRisk,
} from './types';

const listQuestionnairePending = (): QuestionnaireActionType => {
  return {
    type: LIST_QUESTIONNAIRE_PENDING,
  };
};

const listQuestionnaireSuccess = (
  questionnaires: Array<Questionnaire>
): QuestionnaireActionType => {
  return {
    type: LIST_QUESTIONNAIRE_SUCCESS,
    questionnaires: questionnaires,
  };
};

const listQuestionnaireFail = (
  errorMessage: string
): QuestionnaireActionType => {
  return {
    type: LIST_QUESTIONNAIRE_FAIL,
    errorMessage: errorMessage,
  };
};

export const listQuestionnaires = (): AppThunk => {
  return (dispatch: any) => {
    dispatch(listQuestionnairePending());
    axios
      .get(url(API.questionnaires))
      .then((response) => {
        dispatch(listQuestionnaireSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(listQuestionnaireFail(message));
      });
  };
};

const getRiskPending = (): QuestionnaireActionType => {
  return {
    type: GET_RISK_PENDING,
  };
};

const getRiskFailed = (errorMessage: string): QuestionnaireActionType => {
  return {
    type: GET_RISK_FAIL,
    errorMessage: errorMessage,
  };
};

const getRiskSuccess = (dataRisk: DataRisk): QuestionnaireActionType => {
  return {
    type: GET_RISK_SUCCESS,
    dataRisk: dataRisk,
  };
};

export const clearDataRisk = (): QuestionnaireActionType => {
  return {
    type: CLEAR_DATA_RISK,
  };
};

export const getRisk = (dataForm: any): AppThunk => {
  return (dispatch: any) => {
    dispatch(getRiskPending());
    axios
      .post(url(API.risk), dataForm)
      .then((response) => {
        dispatch(getRiskSuccess(response.data));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getRiskFailed(message));
      });
  };
};
