import React, { CSSProperties, useEffect } from 'react';
import { VirusScreenLayout } from '../../components/layout';
import { Alert, Form, Spin } from 'antd';
import { useState } from 'react';
import Questionnaire from './questionnaire';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRisk,
  listQuestionnaires,
} from '../../services/questionnaire/actions';
import { RootState } from '../../services';
import {
  QuestionProps,
  QuestionsProps,
} from '../../services/questionnaire/types';
import moment from 'moment';
import { DATE_ISO_FORMAT } from '../../constants';
import { usePrevious } from '../../utils/utils';
import { useHistory } from 'react-router-dom';

const { Provider } = Form;
interface Props {
  title?: string;
}

const QustionareScreen: React.FC<Props> = (props) => {
  const [step, setStep] = useState<number>(0);
  const [storeDict, setStoreDict] = useState<Map<string, string>>(new Map());

  const [questionnaireDict, setQuestionnaireDict] = useState<
    Map<string, QuestionsProps>
  >(new Map());
  const [stepQuestionnaireName, setStepQuestionnaireName] = useState<
    Array<string>
  >([]);
  const [footer, setFooter] = useState<Map<string,Element | JSX.Element>>(new Map())

  const dispatch = useDispatch();
  const questionnaires = useSelector(
    (state: RootState) => state.questionnaire.questionnaires
  );
  const isGettingQuestionnaires = useSelector(
    (state: RootState) => state.questionnaire.isGettingQuestionnaires
  );
  const isGettingRisk = useSelector(
    (state: RootState) => state.questionnaire.isGettingRisk
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const error = useSelector(
    (state: RootState) => state.questionnaire.errorMessage
  );
  const history = useHistory();

  const insertToDict = (questionnaires: any[]) => {
    questionnaires.forEach((questionnaire) => {
      questionnaire.questions.forEach((question: QuestionProps, i: number) => {
        const newName = questionnaire.name + i;
        setQuestionnaireDict(
          new Map(
            questionnaireDict.set(newName, {
              ...questionnaire,
              questionnaireName: question.title,
              questions: [question],
            })
          )
        );
        if (stepQuestionnaireName.every((current) => current !== newName)) {
          stepQuestionnaireName.push(newName);
          setStepQuestionnaireName(stepQuestionnaireName);
        }
      });
    });
  };
  const prevIsGettingQuestionnaires = usePrevious(isGettingQuestionnaires);
  const prevIsGettingRisk = usePrevious(isGettingRisk);

  useEffect(() => {
    setIsLoading(true);
    dispatch(listQuestionnaires());
  }, []);

  useEffect(() => {
    if (
      !isGettingQuestionnaires &&
      prevIsGettingQuestionnaires === !isGettingQuestionnaires &&
      !error
    ) {
      setIsLoading(false);
    }
    if (!isGettingRisk && prevIsGettingRisk === !isGettingRisk && !error) {
      setIsLoading(false);
    } else if (isGettingRisk) {
      setIsLoading(true);
    }
  }, [isGettingQuestionnaires, isGettingRisk]);

  useEffect(() => {
    if (error.length > 0) {
      setIsLoading(false);
      setIsShowAlert(true);
    } else {
      setIsShowAlert(false);
    }
  }, [error]);

  useEffect(() => {
    if (questionnaires.length > 0) {
      insertToDict(questionnaires);
    }
  }, [questionnaires]);

  const validateRisk = (dict: Map<string, string>) => {
    let answers: Object = {};
    dict.forEach((value: string, key: string) => {
      answers = {
        ...answers,
        [key]: moment.isMoment(value) ? value.format(DATE_ISO_FORMAT) : (value ? value : []),
      };
    });
    dispatch(getRisk({ answers, questionnaire: questionnaires[0] }));
  };

  const removeStoreDict = async (keys: Array<string>) => {
    // remove store of data when field of sup_type was unselected.
    const field = stepQuestionnaireName[step];
    const questionnaire = questionnaireDict.get(field);
    let dict = storeDict;
    questionnaire?.questions.forEach((question) => {
      question.field_options?.forEach((field_option) => {
        if (keys[0] !== undefined && !keys[0].includes(field_option.name)) {
          setStoreDict((prev) => {
            const newMap = new Map(prev);
            newMap.delete(field_option.name + '_supplement');
            dict.delete(field_option.name + '_supplement');
            return newMap;
          });
        }
      });
    });
    return dict;
  };

  const removeQuestionnaireDict = () => {
    const field = stepQuestionnaireName[step];
    if (field.includes('sup_type')) {
      setQuestionnaireDict((prev) => {
        const newMap = new Map(prev);
        newMap.delete(field);
        return newMap;
      });
      stepQuestionnaireName.splice(step, 1);
      setStepQuestionnaireName(stepQuestionnaireName);
    }
  };

  const nextHandler = (keys: Array<string>) => {
    if (step < questionnaireDict.size - 1) {
      removeStoreDict(keys);
      setStep(step + 1);
    } else {
      removeStoreDict(keys).then((dict) => validateRisk(dict));
    }
  };

  const backHandler = () => {
    if (step > 0) {
      removeQuestionnaireDict();
      setStep(step - 1);
    } else {
      history.push('/');
    }
  };

  const createNewQuestionnaire = (
    keys: string[],
    vals: string[],
    questionnaire: QuestionsProps | undefined
  ) => {
    keys.forEach((key, i) => {
      const questions = questionnaire?.questions.filter(
        (question) => question.field_name === key
      );
      const field_options = questions!.map(
        (question) => question.field_options
      );
      const fieldOption =
        Array.isArray(field_options![0]) &&
        field_options![0].filter(
          (field) =>
            field.sup_type &&
            (field.name === vals[i] ||
              (Array.isArray(vals[i]) &&
                vals[i].length > 0 &&
                vals[i].includes(field.name)))
        );
      if (fieldOption && fieldOption!.length > 0) {
        const { image } = questions!.filter(
          (question) => question.field_name === key
        )[i];
        const newQuestions = fieldOption.map((field, i) => {
          const field_name = field.name + '_supplement';
          const type = field.sup_type!;
          return { image, name: field.label, field_name, type };
        });
        const newName = stepQuestionnaireName[step] + 'sup_type';
        if (!questionnaireDict.get(newName)) {
          setQuestionnaireDict(
            new Map(
              questionnaireDict.set(newName, {
                ...questionnaire,
                questionnaireName: questionnaire!.questionnaireName,
                questions: newQuestions,
              })
            )
          );
          stepQuestionnaireName.splice(step + 1, 0, newName);
          setStepQuestionnaireName(stepQuestionnaireName);
        }
      }
    });
  };

  return (
    <Provider
      onFormFinish={(_, { values }) => {
        const keys = Object.keys(values);
        const vals = Object.values(values);
        const questionnaire = questionnaireDict.get(
          stepQuestionnaireName[step]
        );
        if (vals.length === 1 && vals[0] === undefined) {
          keys.forEach((key, i) => {
            setStoreDict(new Map(storeDict.set(key, vals[i])));
          });
        }
        createNewQuestionnaire(keys, vals, questionnaire);
        nextHandler(vals);
      }}
      onFormChange={(_, { changedFields }) => {
        changedFields.forEach((field) => {
          const { name, value } = field;
          const nameStr = typeof name !== 'string' ? name.toString() : name;
          setStoreDict(new Map(storeDict.set(nameStr, value)));
        });
      }}
    >
      {questionnaireDict.size > 0 ? (
        stepQuestionnaireName.map((field, i) => {
          const questionnaire = questionnaireDict.get(field);
          if (questionnaire && field === stepQuestionnaireName[step]) {
            return (
              <VirusScreenLayout
                title={questionnaire.questionnaireName}
                key={`questionnaire-screen-${i}`}
                footer={footer.get(field)}
                hasBackground={true}
              >
                {isShowAlert && (
                  <Alert
                    message={error}
                    type="error"
                    style={styles.errorMessage}
                    showIcon
                  />
                )}
                <Spin spinning={isLoading} style={styles.spinner}>
                  <div style={styles.container}>
                    <Questionnaire
                      questions={questionnaire.questions}
                      backHandler={backHandler}
                      key={`questionnaire-id-${i}`}
                      id={i}
                      initialValue={storeDict}
                      setFooter={(buttonFooter) =>
                        setFooter(new Map(footer.set(field, buttonFooter)))
                      }
                    />
                  </div>
                </Spin>
              </VirusScreenLayout>
            );
          }
        })
      ) : (
        <VirusScreenLayout
          title={props.title}
          key={`default-questionnaire-screen`}
        >
          {error && (
            <div style={styles.styleError}>
              <Alert
                message={error}
                type="error"
                style={styles.errorMessage}
                showIcon
              />
            </div>
          )}
          <div style={styles.spinner}>
            <Spin spinning={isLoading} />
          </div>
        </VirusScreenLayout>
      )}
    </Provider>
  );
};

export interface StylesDictionary {
  [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
  },
  textArea: {
    flex: 1,
    flexGrow: 1,
  },
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  errorMessage: {
    marginTop: 16,
    marginBottom: 16,
    maxWidth: '100%',
  },
  styleError: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'center',
},
};

export default QustionareScreen;
