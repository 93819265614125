import React, { useEffect } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import { Color, KanitFont, YearDifference } from '../../constants';
import { StylesDictionary } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services';
import { useHistory, useLocation} from 'react-router-dom';
import { Spin } from 'antd';
import { PrimaryButton } from '../../components/button';
import { Card } from 'antd';
import moment from 'moment';
import { getListAppointment } from '../../services/appointment/actions';
interface Props {
    title?: string;
}

const ShowAllAppointmentScreen: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const listAppointment = useSelector((state: RootState) => state.appointment.listAppointment);
    const { state } = useLocation();
    const param: any = state;
    const history = useHistory();
    const isListGettingAppointment = useSelector((state: RootState) => state.appointment.isGettingListAppointment);

    const footer = () => {
        return (
            <div style={styles.buttonBox}>
                <PrimaryButton title='ย้อนกลับ' style={styles.button} onClick={() => history.push(`/appointment/my/`)} />
            </div>
        )
    }

    useEffect(()=>{
        dispatch(getListAppointment());
    },[])

    const convertStatus = (status: any) =>{
        if(status  === 'CP'){
            return <h4 style={{color: Color.green}}> เสร็จเเล้ว </h4>
        }else if(status === 'CC'){
            return <h4 style={{color: Color.red}}> ยกเลิกเเล้ว </h4>
        }
    } 

    const goShowDetailAppointment = (id : any) => {
        history.push(`/appointment/${id}`, { title: 'รายละเอียดของการนัดหมาย' });
    }
    
    const title = param?.title ?? props.title;

    const render = (data: any) => {
       return <div className={'card-style'} onClick={ e => goShowDetailAppointment(data.id)}  >
           {data.status !== 'CF' && <Card title={data.appointment_no} style={{  color: Color.chetwodeBlue}}>
                <h1 style={styles.textColor}>{`เวลา ${moment(data.when).add(YearDifference, 'year').format('H:mm').split(',')} น.`}</h1>
                <h3 style={styles.textColor}>{`วันที่ ${moment(data.when).add(YearDifference, 'year').format('D MMMM YYYY').split(',')}`}</h3>
                <h3 style={styles.textColor}>{data.venue}</h3>
                <br/>
                {convertStatus(data.status)}
            </Card>}
            <br/>
        </div>
    }

    return (
        <Spin spinning={isListGettingAppointment}>
            <VirusScreenLayout title={title} hasBackground={true} footer={footer()}>
                {listAppointment.map(product => (
                  render(product)
                ))} 
            </VirusScreenLayout>
        </Spin>
    );
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        alignItems: 'center',
    },
    containArea: {
        flex: 1,
        flexGrow: 1,
        width: '100',
        textAlign: 'center',
    },
    text: {
        fontFamily: KanitFont,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: Color.eclipse,
    },
    link: {
        fontFamily: KanitFont,
        fontSize: 16,
        textAlign: 'center'
    },
    textColor: {
        color: Color.chetwodeBlue,
    },
    marginVertical: {
        marginTop: 16,
        marginBottom: 16,
    },
    NoMargin: {
        margin: 0,
    },
    styleQrcode: {
        backgroundColor: Color.white,
        alignSelf: 'center',
        padding: 16,
        zIndex: 1,
        marginTop: 16,
        marginBottom: 16,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Color.eclipse,
        boxSizing: 'border-box',
        borderRadius: 4,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 16,
    },
    button: {
        marginTop: 8,
        height: 40,
        width: '100%',
    },
}

export default ShowAllAppointmentScreen;
