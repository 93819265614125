import { Action, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { administrativeReducer } from './administrative/reducers';
import { AppointmentReducer } from './appointment/reducers';
import { questionnaireReducer } from './questionnaire/reducers';
import { registerReducer } from './register/reducers';
import { resultsReducer } from './results/reducers';
import { profileReducer } from './profile/reducers';
import { authReducer } from './auth/reducers';
import { featureFlagReducer } from './feature_flag/reducers';

export const rootReducer = combineReducers({
    questionnaire: questionnaireReducer,
    register: registerReducer,
    administrative: administrativeReducer,
    appointment: AppointmentReducer,
    results: resultsReducer,
    profile: profileReducer,
    auth: authReducer,
    featureFlag: featureFlagReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
