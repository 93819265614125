export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const SENDING_OTP_PENDING = "SENDING_OTP_PENDING";
export const SENDING_OTP_SUCCESS = "SENDING_OTP_SUCCESS";
export const SENDING_OTP_FAILED = "SENDING_OTP_FAILED";

export const SET_FORM = "SET_FORM";

export const CLEAR_ERROR = "CLEAR_ERROR";

export interface AuthState {
  errorMessage: string;
  isLoggingIn: boolean;
  isSendingOTP: boolean;
  token: string;
  phone_no: string;
  otpToken: string;
}

export interface setFormAction {
  type: typeof SET_FORM;
  phone_no: string;
}

export interface LoginPendingAction {
  type: typeof LOGIN_PENDING;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
}

export interface LoginFailedAction {
  type: typeof LOGIN_FAILED;
  error: string;
}

export interface SendingOTPPendingAction {
  type: typeof SENDING_OTP_PENDING;
}

export interface SendingOTPSuccessAction {
  type: typeof SENDING_OTP_SUCCESS;
  otpToken: string;
}

export interface SendingOTPFailedAction {
  type: typeof SENDING_OTP_FAILED;
  error: string;
}

export interface ClearErrorAction {
  type: typeof CLEAR_ERROR;
  error: string;
}

export type AuthActionType =
  | setFormAction
  | LoginPendingAction
  | LoginSuccessAction
  | LoginFailedAction
  | SendingOTPPendingAction
  | SendingOTPSuccessAction
  | SendingOTPFailedAction
  | ClearErrorAction;
