import React, { useEffect } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
  PrimaryButton,
} from '../../components/button';
import Lung from '../../assets/images/lung.svg';
import WashHand from '../../assets/images/wash_hand.svg';
import Medicine from '../../assets/images/medicine.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../services';
import { History } from 'history';
import { Color, KanitFont } from '../../constants';
import { StylesDictionary } from '../../utils/utils';
import { DataRisk } from '../../services/questionnaire/types';
interface Props {
  title?: string;
}

const checkRisk = (dataRisk: DataRisk, history: History<unknown> | string[]) => {
  switch (dataRisk.risk) {
    case 'high':
      return (
        <div style={styles.highRiskContent}>
          <div style={styles.styleIconRisk}>
            <img src={Lung} alt="lung-logo"/>
          </div>
          <div style={styles.textRisk}>
            คุณมีความเสี่ยงสูง
          </div>
          <div style={styles.textNormal}>
            คุณจะต้องไปทำการตรวจคัดกรองที่
          </div>
          <div style={styles.textVenue}>
            {dataRisk.venue}
          </div>
          <div style={styles.textNormal}>
            โดยการตรวจของคุณจะไม่มีค่าใช้จ่าย
          </div>
        </div>
      );
    case 'no':
      return (
        <div>
          <div style={styles.textCenter}>
            คุณไม่มีความเสี่ยงไม่ต้องทำการตรวจ
            คัดกรองการติดเชื้อ COVID-19
            </div>
          <div style={styles.textContent}
               dangerouslySetInnerHTML={{ __html: dataRisk.message }} />
          <div style={styles.styleIconRisk}>
            <img src={WashHand} alt="wash-hand-logo"/>
          </div>
        </div>
      );
    case 'other':
      return (
        <div>
          <div style={styles.textRisk}>
            คุณไม่มีความเสี่ยงติดเชื้อ COVID-19 แต่อาจมีอาการของโรคอื่น
            </div>
          <div style={styles.textContent}
               dangerouslySetInnerHTML={{ __html: dataRisk.message }} />
          <div style={styles.styleIconRisk}>
            <img src={Medicine} alt="medicine-logo" />
          </div>
        </div>
      );
    case 'low':
      return (
        <div>
          <div style={styles.textRisk}>
            คุณมีความเสี่ยงต่ำที่จะติดเชื้อ COVID-19
            </div>
          <div style={styles.textContent}
               dangerouslySetInnerHTML={{ __html: dataRisk.message }} />
          <div style={styles.styleIconRisk}>
            <img src={Medicine} alt="medicine-logo" />
          </div>
        </div>
      );
  }
}

const footer = (dataRisk: DataRisk, history: History<unknown> | string[]) => {
    switch (dataRisk.risk) {
      case 'high':
        return (
          <div style={styles.buttonBox}>
            <PrimaryButton
              title="ลงทะเบียนเพื่อทำการนัดหมาย"
              style={styles.buttonRegister}
              onClick={() => history.push('/register')}
            />
          </div>
        );
      default:
        return (
          <div style={styles.buttonBox}>
            <PrimaryButton
              title="สิ้นสุดการประเมิน"
              style={styles.buttonRegister}
              onClick={() => history.push('/')}
            />
          </div>
        );
    }
}

const RiskScreen: React.FC<Props> = (props) => {
  const history = useHistory();
  const dataRisk = useSelector((state: RootState) => state.questionnaire.dataRisk);

  useEffect(() => {
    if (dataRisk.risk.length === 0) {
      history.push('/questionnaires');
    }
  }, [])

  return (
    <VirusScreenLayout title={props.title} footer={footer(dataRisk,history)} hasBackground={true}>
      <div style={styles.container}>
        {checkRisk(dataRisk, history)}
      </div>
    </VirusScreenLayout>
  );
}

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignSelf: 'center',
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
  },
  textRisk: {
    flex: 1,
    flexGrow: 1,
    alignSelf: 'center',
    marginTop: 24,
    color: Color.chetwodeBlue,
    fontFamily: KanitFont,
    fontSize: 16,
    textAlign: 'center',
  },
  textVenue: {
    flex: 1,
    flexGrow: 1,
    alignSelf: 'center',
    marginTop: 24,
    color: Color.chetwodeBlue,
    fontFamily: KanitFont,
    fontSize: 16,
  },
  textNormal: {
    flex: 1,
    flexGrow: 1,
    alignSelf: 'center',
    marginTop: 24,
    fontFamily: KanitFont,
    fontSize: 16,
  },
  textCenter: {
    flex: 1,
    color: Color.chetwodeBlue,
    flexGrow: 1,
    alignSelf: 'center',
    marginTop: 24,
    fontFamily: KanitFont,
    fontSize: 16,
    textAlign: 'center',
  },
  textContent: {
    flex: 1,
    flexGrow: 1,
    marginTop: 24,
    fontFamily: KanitFont,
    fontSize: 16,
  },
  buttonRegister: {
    zIndex: 1,
    height: 40,
    width: '100%',
  },
  styleIconRisk: {
    alignSelf: 'center',
    zIndex: 1,
    marginTop: 24,
    textAlign: 'center',
  },
  highRiskContent: {
    textAlign: 'center',
  }
}

export default RiskScreen;
