export const LIST_QUESTIONNAIRE_PENDING = 'LIST_QUESTIONNAIRE_PENDING';
export const LIST_QUESTIONNAIRE_SUCCESS = 'LIST_QUESTIONNAIRE_SUCCESS';
export const LIST_QUESTIONNAIRE_FAIL = 'LIST_QUESTIONNAIRE_FAIL';
export const GET_RISK_PENDING = 'GET_RISK_PENDING';
export const GET_RISK_SUCCESS = 'GET_RISK_SUCCESS';
export const GET_RISK_FAIL = 'GET_RISK_FAIL';
export const CLEAR_DATA_RISK = 'CLEAR_DATA_RISK';

export interface QuestionnaireState {
  isGettingQuestionnaires: boolean;
  questionnaires: Array<Questionnaire>;
  isGettingRisk: boolean;
  dataRisk: DataRisk;
  errorMessage: string;
}

export interface Questionnaire {
  name: string;
  questions: Array<QuestionProps>;
}

export interface AppError {
  message: string;
}

enum InputTypeQuestionnaire {
  text = 'TX',
  number = 'NU',
  dateTime = 'DT',
}

enum SelectTypeQuestionnaire {
  singleSelected = 'SS',
  multipleSelected = 'MS',
}

export interface Field {
  label: string;
  name: string;
}

export interface Option extends Field {
  sup_type?: InputTypeQuestionnaire | string;
}

interface Store {
  [name: string]: any;
}
export interface QuestionProps {
  type: InputTypeQuestionnaire | SelectTypeQuestionnaire | string;
  title?: string;
  name: string;
  image: string;
  field_options?: Array<Option>;
  field_max?: number;
  field_max_length?: number;
  field_min?: number;
  field_name: string;
  field_step?: number;
  detail?: string;
}

export interface QuestionsProps {
  questions: Array<QuestionProps>;
  backHandler?: () => void;
  id?: number;
  initialValue?: Store;
  questionnaireName?: string;
  setFooter?: (footer: JSX.Element) => void;
}

export interface ListQuestionnairePendingAction {
  type: typeof LIST_QUESTIONNAIRE_PENDING;
}

export interface ListQuestionnaireSuccessAction {
  type: typeof LIST_QUESTIONNAIRE_SUCCESS;
  questionnaires: Array<Questionnaire>;
}

export interface ListQuestionnaireFailAction {
  type: typeof LIST_QUESTIONNAIRE_FAIL;
  errorMessage: string;
}

export interface DataRisk {
  id: string;
  message: string;
  risk: string;
  venue: string;
}

export interface GetRiskPendingAction {
  type: typeof GET_RISK_PENDING;
}

export interface GetRiskSuccessAction {
  type: typeof GET_RISK_SUCCESS;
  dataRisk: DataRisk;
}

export interface ClearDataRiskAction {
  type: typeof CLEAR_DATA_RISK;
}

export interface GetRiskFailAction {
  type: typeof GET_RISK_FAIL;
  errorMessage: string;
}

export { InputTypeQuestionnaire, SelectTypeQuestionnaire };

export type QuestionnaireActionType =
  | ListQuestionnairePendingAction
  | ListQuestionnaireSuccessAction
  | ListQuestionnaireFailAction
  | GetRiskPendingAction
  | GetRiskSuccessAction
  | GetRiskFailAction
  | ClearDataRiskAction;
