import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
    PrimaryButton,
    SecondaryButton,
} from '../../components/button';
import { useHistory } from 'react-router-dom';
import { StylesDictionary, usePrevious } from '../../utils/utils';
import { Alert, Form, Input, Spin } from 'antd';
import NumberFormat from 'react-number-format';
import { KanitFont, Color } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { login, requestOTP, clearError } from '../../services/auth/actions';
import { RootState } from '../../services';

interface Props {
    title?: string;
    go?: string;
}

const LoginScreen: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const phone_no = useSelector((state: RootState) => state.auth.phone_no);
    const isSendingOTP = useSelector((state: RootState) => state.auth.isSendingOTP);
    const errorMessage = useSelector((state: RootState) => state.auth.errorMessage);
    const prevIsSendingOTP = usePrevious(isSendingOTP);
    const [time, setTime] = useState(0)
    const isLoggingIn = useSelector((state: RootState) => state.auth.isLoggingIn)
    const prevIsLoggingIn = usePrevious(isLoggingIn)
    const [resend, setResend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    
    const getOTP = (e: any) => {
        form.setFieldsValue({ otp: e.value });
        form.validateFields(['otp'])
    }

    const onSubmit = async () => {
        const values = await form.validateFields();
        setLoading(true);
        dispatch(login(phone_no, values.otp));
    }

    const onResend = () => {
        setResend(true);
        dispatch(requestOTP(phone_no));
    }
    
    const onBack = () => {
        dispatch(clearError());
        history.push('/auth/get_otp');
    }

    useEffect(() => {
        if (!isLoggingIn && prevIsLoggingIn === !isLoggingIn && !errorMessage) {
            setLoading(false);
            if (props.go) {
                history.push(props.go);
            }
        } else if (!isLoggingIn && prevIsLoggingIn === !isLoggingIn && errorMessage) {
            setLoading(false);
        }
    }, [isLoggingIn, errorMessage]);

    useEffect(() => {
        if (resend && !isSendingOTP && prevIsSendingOTP === !isSendingOTP && !errorMessage) {
            setTime(30);
        }
    }, [isSendingOTP]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000)
        return () => {
            clearTimeout(timeOut)
        }
    }, [time]);

    const footer = () => {
        return (
          <div style={styles.buttonBox}>
            <SecondaryButton
              style={{ ...styles.button, ...styles.buttonLeft }}
              title="ย้อนกลับ"
              onClick={onBack}
            />
            <PrimaryButton
              style={{ ...styles.button, ...styles.buttonRight }}
              title="ยืนยัน"
              onClick={onSubmit}
              disabled={isError}
            />
          </div>
        );
    }

    return (
        <Spin spinning={loading}>
            <VirusScreenLayout title={props.title} footer={footer()} >
                <Form
                    layout="vertical"
                    name="basic"
                    form={form}
                    style={styles.textArea}
                    className='auth-page'
                    onFieldsChange={(fieldsValue) => setIsError(!!fieldsValue.filter(({ errors }) => errors!.length).length)}
                >
                    <h3 style={styles.title}>{'กรุณากรอกเลข OTP'}</h3>
                    <h3 style={styles.textH5}>{`ที่ส่งไปยังหมายเลข ${phone_no}`}</h3>
                    <Form.Item
                        name="otp"
                        rules={[
                            { required: true, message: 'กรุณากรอกOTP' },
                            { min: 6, message: 'OTPมี 6 ตัวเท่านั้น' },
                            { max: 6, message: 'OTPมี 6 ตัวเท่านั้น' },
                        ]}
                    >
                        <Input hidden />
                        <NumberFormat
                            onValueChange={getOTP}
                            className="ant-input"
                            style={styles.input}
                            placeholder="XXXXXX"
                            format="######"
                            mask="_"
                        />
                    </Form.Item>
                    <a style={{ textAlign: 'center' }} onClick={time > 0 ? undefined : onResend}>
                        {time > 0 ? 'ส่งรหัสเเล้ว กรุณารอ ' + time + ' วินาที ก่อนจะส่งอีกครั้ง' : 'ส่งรหัสยืนยันใหม่อีกครั้ง'}
                    </a>
                </Form>
                <div style={styles.styleError}>
                    {errorMessage && (
                        <Alert
                            message={errorMessage}
                            type="error"
                            style={styles.errorMessage}
                            showIcon
                        />
                    )}
                </div>
            </VirusScreenLayout>
        </Spin>
    );
}

const styles: StylesDictionary = {
    bg: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    textArea: {
        flex: 1,
        textAlign: 'center',
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
    },
    button: {
        marginTop: 8,
        height: 40,
        width: '100%',
    },
    buttonLeft: {
        marginRight: 12,
    },
    buttonRight: {
        marginLeft: 12,
    },
    input: {
        fontFamily: KanitFont,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        textAlign: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Color.pictonBlue,
        borderRadius: 4,
        boxSizing: 'border-box',
        width: '100%',
        height: 40,
        color: Color.pictonBlue,
        padding: 0,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: Color.white,
    },
    title: {
        fontFamily: KanitFont,
        fontSize: 16,
        textAlign: 'center',
        fontStyle: 'normal',
    },
    textH5: {
        fontFamily: KanitFont,
        fontSize: 13,
        textAlign: 'center',
        fontStyle: 'normal',
    },
    styleError: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        alignItems: 'center',
    },
    errorMessage: {
        marginTop: 16,
        marginBottom: 16,
        maxWidth: '100%',
    },
}

export default LoginScreen;
