import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
    PrimaryButton,
    SecondaryButton,
} from '../../components/button';
import ProfileConfirm from '../../assets/images/profile_confirm.svg';
import { Color, KanitFont } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services';
import { useHistory } from 'react-router-dom';
import { register } from '../../services/register/actions';
import { StylesDictionary, usePrevious } from '../../utils/utils';
import { AddressInfo } from '../../services/administrative/types';
import { Alert, Spin } from 'antd';
import NumberFormat from 'react-number-format';
import Title from 'antd/lib/typography/Title';
interface Props {
    title?: string;
}

const ConfirmRegisterScreen: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const address = useSelector((state: RootState) => state.administrative.address);
    const { errorMessage, listErrors, registerInfo } = useSelector((state: RootState) => state.register);
    const isRegistering = useSelector((state: RootState) => state.register.isRegistering);
    const [provinces, setProvinces] = useState<any>({});
    const [districts, setDistricts] = useState<any>({});
    const [subdistricts, setSubdistricts] = useState<any>({});
    const history = useHistory();
    const prevIsRegistering = usePrevious(isRegistering);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const addressMap = (item: AddressInfo[]) => Object.assign(
        {},
        ...item.map((i) => ({
            [i.id]: i,
        }))
    );

    useEffect(() => {
        if (!registerInfo.citizen_id && !registerInfo.passport_id) {
            history.push('/auth/register');
        };
        setProvinces(addressMap(address.provinces));
        setDistricts(addressMap(address.districts));
        setSubdistricts(addressMap(address.subdistricts));
    }, [])

    useEffect(() => {
        if (!isRegistering && prevIsRegistering === !isRegistering && !errorMessage) {
            setLoading(false);
            history.push('/appointment');
        } else if (!isRegistering && prevIsRegistering === !isRegistering && errorMessage) {
            setShowAlert(true);
            setLoading(false);
        }
    }, [isRegistering, errorMessage])

    const onSubmit = () => {
        setShowAlert(false);
        setLoading(true);
        dispatch(register(registerInfo));
    }
    const footer = () => {
        return (
            <div style={styles.buttonBox}>
                <SecondaryButton
                    style={{ ...styles.button, ...styles.buttonLeft }}
                    title="แก้ไขข้อมูล"
                    onClick={() => history.goBack()}
                />
                <PrimaryButton
                    style={{ ...styles.button, ...styles.buttonRight }}
                    title="ยืนยัน"
                    onClick={onSubmit}
                />
            </div>
        );
    }

    return (
        <VirusScreenLayout title={props.title} footer={footer()} hasBackground={true}>
            <Spin spinning={loading}>
                <div style={styles.container}>
                    <img src={ProfileConfirm} alt="user-profile-icon"/>
                    <div style={styles.containArea}>
                        <h3 style={styles.title}>{"ยืนยันข้อมูล"}</h3>
                        <div style={styles.textInLine}>
                            <Title level={5} style={styles.text}>{`ชื่อ: ${registerInfo.firstname}`}</Title>
                            {showAlert && listErrors?.firstname && (
                                <Alert
                                    message={listErrors?.firstname}
                                    type="error"
                                    style={styles.listErrors}
                                    showIcon
                                />
                            )}
                        </div>
                        <div style={styles.textInLine}>
                            <Title level={5} style={styles.text}>{`นามสกุล: ${registerInfo.lastname}`}</Title>
                            {showAlert && listErrors?.lastname && (
                                <Alert
                                    message={listErrors?.lastname}
                                    type="error"
                                    style={styles.listErrors}
                                    showIcon
                                />
                            )}
                        </div>
                        {registerInfo.citizen_id && (
                            <div style={styles.textInLine}>
                                <h5>{'เลขบัตรประชาชน: '}
                                    <NumberFormat
                                        value={registerInfo.citizen_id}
                                        format="#-####-#####-##-#"
                                        displayType='text'
                                    />
                                </h5>
                                {showAlert && listErrors?.citizen_id && (
                                    <Alert
                                        message={listErrors?.citizen_id}
                                        type="error"
                                        style={styles.listErrors}
                                        showIcon
                                    />
                                )}
                            </div>
                        )}
                        {registerInfo.passport_id && (
                            <div style={styles.textInLine}>
                                <h5>{`เลขยืนยันตัวตนอื่น: ${registerInfo.passport_id}`}</h5>
                                {showAlert && listErrors?.passport_id && (
                                    <Alert
                                        message={listErrors?.passport_id}
                                        type="error"
                                        style={styles.listErrors}
                                        showIcon
                                    />
                                )}
                            </div>
                        )}
                        <div style={styles.textInLine}>
                            <Title level={5} style={styles.text}>{`ที่อยู่: ${registerInfo.address}`}</Title>
                            {showAlert && listErrors?.address && (
                                <Alert
                                    message={listErrors?.address}
                                    type="error"
                                    style={styles.listErrors}
                                    showIcon
                                />
                            )}
                        </div>
                        <div style={styles.textInLine}>
                            <h5>{`ตำบล ${subdistricts[registerInfo.subdistrict_id]?.name} อำเภอ ${districts[registerInfo.district_id]?.name}`}</h5>
                            {(showAlert && listErrors?.subdistrict_id) || (listErrors?.district_id && (
                                <Alert
                                    message={[listErrors?.subdistrict_id, listErrors?.district_id]}
                                    type="error"
                                    style={styles.listErrors}
                                    showIcon
                                />
                            ))}
                        </div>
                        <div style={styles.textInLine}>
                            <h5>{`จังหวัด ${provinces[registerInfo.province_id]?.name}`}</h5>
                            {(showAlert && listErrors?.province_id) && (
                                <Alert
                                    message={listErrors?.province_id}
                                    type="error"
                                    style={styles.listErrors}
                                    showIcon
                                />
                            )}
                        </div>
                        <h5>{'โทรศัพท์: '}
                            <NumberFormat
                                value={registerInfo.phone_no}
                                format="###-###-####"
                                displayType='text'
                            />
                        </h5>
                    </div>
                    {showAlert && (
                        <Alert
                            message={errorMessage}
                            type="error"
                            style={styles.errorMessage}
                            showIcon
                        />
                    )}
                </div>
            </Spin>
        </VirusScreenLayout>
    );
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        alignItems: 'center',
    },
    containArea: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 16,
        width: '100%',
    },
    button: {
        marginTop: 8,
        height: 40,
        width: '100%',
    },
    buttonLeft: {
        marginRight: 12,
    },
    buttonRight: {
        marginLeft: 12,
    },
    title: {
        fontFamily: KanitFont,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: Color.eclipse,
    },
    text: {
        fontFamily: KanitFont,
        fontSize: 15,
        fontStyle: 'normal',
        fontWeight: 500,
        color: Color.eclipse,
        maxWidth:'70%',
    },
    errorMessage: {
        marginTop: 16,
        maxWidth: '100%',
    },
    listErrors: {
        width: 'fit-content',
        height: 'fit-content',
    },
    textInLine:{
        display: 'flex',
        justifyContent: 'space-between',
    }
}

export default ConfirmRegisterScreen;
