import {
    LOGIN_FAILED,
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    SET_FORM,
    SENDING_OTP_FAILED,
    SENDING_OTP_PENDING,
    SENDING_OTP_SUCCESS,
    CLEAR_ERROR,
    AuthState,
    AuthActionType,
} from './types';

const initialState: AuthState = {
    errorMessage: '',
    phone_no: '',
    isLoggingIn: false,
    isSendingOTP: false,
    token: '',
    otpToken: '',
}

export const authReducer = (state = initialState, action: AuthActionType): AuthState => {
    switch (action.type) {
        case SET_FORM:
            return {
                ...state,
                phone_no: action.phone_no
            };
        case LOGIN_PENDING:
            return {
                ...state,
                isLoggingIn: true,
                errorMessage: '',
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                token: action.token
            };
        case LOGIN_FAILED:
            return {
                ...state,
                errorMessage: action.error,
                isLoggingIn: false,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                errorMessage: '',
            };
        case SENDING_OTP_PENDING:
            return {
                ...state,
                isSendingOTP: true,
                errorMessage: '',
            };
        case SENDING_OTP_SUCCESS:
            return {
                ...state,
                isSendingOTP: false,
                otpToken: action.otpToken,
            };
        case SENDING_OTP_FAILED:
            return {
                ...state,
                errorMessage: action.error,
                isSendingOTP: false,
            };
        default:
            return state;
    }
}
