import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
    PrimaryButton, SecondaryButton,
} from '../../components/button';
import TwoMetersApart from '../../assets/images/two_meters_apart.svg';
import { Color, KanitFont, YearDifference } from '../../constants';
import { StylesDictionary, usePrevious } from '../../utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services';
import { DatesInfo, Venues } from '../../services/appointment/types';
import moment from 'moment';
import { clearError, createAppointment } from '../../services/appointment/actions';
import { Alert, Spin } from 'antd';

interface Props {
    title?: string;
}

const ConfirmAppointmentScreen: React.FC<Props> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const bookQueue = useSelector((state: RootState) => state.appointment.bookQueue);
    const error = useSelector((state: RootState) => state.appointment.error);
    const isCreatingAppointment = useSelector((state: RootState) => state.appointment.isCreatingAppointment);
    const prevIsCreatingAppointment = usePrevious(isCreatingAppointment);
    const [venue, setVenue] = useState<Venues>();
    const date = bookQueue.date && bookQueue.date;
    const [time, setTime] = useState<DatesInfo>();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const evaluationId = useSelector((state: RootState) => state.questionnaire.dataRisk.id);
    const { state } = useLocation();    
    const param: any = state; 

    const onSubmit = () => {
        if(time?.id){
            const data = {
                date: date,
                time_slot_id: time.id,
                evaluation_id: evaluationId,
            };
            setSubmit(true);
            setShowAlert(false);
            setLoading(true);
            dispatch(createAppointment(data));
        }
    }

    const onBack = () => {
        dispatch(clearError());
        history.goBack();
    }

    useEffect(() => {
        if (!bookQueue.date) {
            history.push('/appointment')
        }
    }, [])

    useEffect(() => {
        if (submit) {
            if (!isCreatingAppointment && prevIsCreatingAppointment === !isCreatingAppointment && !error) {
                setLoading(false);
                history.push(`/appointment/my/`, { title: 'นัดหมายเสร็จสิ้น' });
            } else if (!isCreatingAppointment && prevIsCreatingAppointment === !isCreatingAppointment && error) {
                setShowAlert(true);
                setLoading(false);
            }
        }
    }, [isCreatingAppointment, error])

    useEffect(()=>{
        if(param.venues.length > 0){
            const findVenue = param.venues.find((venue:Venues) => venue.id === bookQueue.venue)
            setVenue(findVenue);
        }
        if(param.time.length > 0){
            const findTime = param.time.find((time:DatesInfo) => time.id === bookQueue.timeRange)
            setTime(findTime);
        }
    },[param])

    const footer = () =>{
        return(
        <div style={styles.buttonBox}>
            <SecondaryButton style={{ ...styles.button, ...styles.buttonLeft }} title="เปลี่ยนเวลานัด" onClick={onBack} />
            <PrimaryButton style={{ ...styles.button, ...styles.buttonRight }} title="ยืนยัน" onClick={onSubmit} />
        </div>
        )
    }
    
    return (
    <Spin spinning={loading}>
        <VirusScreenLayout title={props.title} footer={footer()} hasBackground={true}>
                <div style={styles.container}>
                    <div style={styles.containArea}>
                        <h3 style={styles.text}>คุณจะต้องไปทำการตรวจคัดกรองที่</h3>
                        <h3 style={{ ...styles.text, ...styles.textColor }}>{venue?.name}</h3>
                        <h3 style={styles.text}>ใน <span style={{ ...styles.text, ...styles.textColor }}>วันที่ {moment(date).add(YearDifference, 'year').format('D MMMM YYYY')} เวลา {time?.name} น.</span></h3>
                        <h3 style={styles.text}>กรุณาไปตามเวลานัด เนื่องจากมีคิวจำกัด หากคุณผิดนัดหรือไปสาย อาจจะต้องรอนานหรือ ต้องทำการนัดหมายใหม่หากมีผู้ป่วยจำนวนมาก</h3>
                        <h3 style={{...styles.text,color: Color.red}}>คำเตือน: หลังจากยืนยันการจองแล้ว จะเปลี่ยนวันเวลาไม่ได้อีก</h3>
                        <img src={TwoMetersApart} style={styles.imgCenter} alt="keep-distance-2-meter"/>
                    </div>
                    {showAlert && (
                        <Alert
                            message={error}
                            type="error"
                            style={styles.errorMessage}
                            showIcon
                        />
                    )}
                </div>
        </VirusScreenLayout>
    </Spin>
    );
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        alignItems: 'center',
    },
    containArea: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        textAlign: 'center',
    },
    errorMessage: {
        marginTop: 16,
        maxWidth: '100%',
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 16,
    },
    button: {
        marginTop: 8,
        height: 40,
        width: '100%',
    },
    buttonLeft: {
        marginRight: 12,
    },
    buttonRight: {
        marginLeft: 12,
    },
    text: {
        fontFamily: KanitFont,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: Color.eclipse,
        marginTop: 16,
        marginBottom: 16,
    },
    textColor: {
        color: Color.chetwodeBlue,
    },
}

export default ConfirmAppointmentScreen;
