import React, { useEffect } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
  PrimaryButton,
} from '../../components/button';
import IsaraSoftLogo from '../../assets/images/isarasoft_logo.svg';
import HospitalLogo from '../../assets/images/rayong.png';
import Doctor from '../../assets/images/doctor.svg';
import { useHistory } from 'react-router-dom';
import { StylesDictionary } from '../../utils/utils';
import { Color, KanitFont, VERSION } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearBookQueue } from '../../services/appointment/actions';
import { clearError } from '../../services/auth/actions';
import './index.css';
import { RootState } from '../../services';
import { loadFeatureFlag } from '../../services/feature_flag/actions';

const windowHeight = window.innerHeight;

interface Props {
  title?: string;
}

const HomeScreen: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    riskAssessmentEnabled,
    myTestResultEnabled,
  } = useSelector((state: RootState) => state.featureFlag);

  useEffect(() => {
    dispatch(clearError());
    dispatch(loadFeatureFlag());
  }, [])

  const takeQuestionnaire = () => {
    dispatch(clearBookQueue());
    history.push('/questionnaires');
  }

  const takeRegister = () => {
    dispatch(clearBookQueue());
    history.push('/register');
  }

  const openLink = () => {
    window.open("https://isarasoftware.com/");
  }
  
  const footer = () => {
    return (
      <div>
        {
          riskAssessmentEnabled
          ? <PrimaryButton
              title="ทำแบบประเมินความเสี่ยง"
              style={styles.button}
              onClick={takeQuestionnaire} />
          : <PrimaryButton
              title="ลงทะเบียน"
              style={styles.button}
              onClick={takeRegister} />
        }
        <PrimaryButton
          title="ดูการนัดหมาย"
          style={styles.button}
          onClick={() => history.push(`/appointment/my/`)}
        />
        {
          myTestResultEnabled
          ? <>
              <PrimaryButton
                title="ดูผลการตรวจ"
                style={styles.button}
                onClick={() => history.push(`/result/my/`)} />
              <PrimaryButton
                title="ดูผลการตรวจด้วยวันเกิด"
                style={styles.button}
                onClick={() => history.push(`/login_with_birthday`)} />
            </>
          : null
        }
        <div style={styles.homeFooter}>
          <div style={styles.img}>
            <img src={IsaraSoftLogo} className="HospitalLogo" alt="rayong-hospital-logo" onClick={() => openLink()} />
          </div>
          <div style={styles.img}>
            <img src={HospitalLogo} className="HospitalLogo" alt="rayong-hospital-logo"  />
          </div>
          <div style={styles.version}>
            <p style={styles.versionText}>{`V.${VERSION}`}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <VirusScreenLayout title={props.title} layoutStyles={styles.layout} hiddenHeader={true} footer={footer()} contentStyles={styles.content}>
      <div style={styles.container}>
        <div style={styles.img}>
          <img src={Doctor} alt="doctor-logo" />
        </div>
        <p style={styles.text}>ระบบจองคิวตรวจเชื้อหาเชื้อโควิด-19 ด้วยวิธี Rapid Test<br/>ขอสงวนสิทธิ์ให้เฉพาะผู้ที่อาศัยอยู่ในเขตเทศบาลนครระยองเท่านั้น</p>
      </div>
    </VirusScreenLayout>
  );
}

const styles: StylesDictionary = {
  container: {
    flexFlow: 'column',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: KanitFont,
    fontStyle: 'normal',
    marginTop: 24,
  },
  img: {
    alignSelf: 'center',
    marginTop: 24,
  },
  layout: {
    background: `linear-gradient(180deg, ${Color.bermuda} 0%, ${Color.lavender} 100%)`,
    minHeight: windowHeight,
    maxHeight: 'max-content',
  },
  button: {
    width: '100%',
    marginTop: 15,
    height: 40,
    fontFamily: KanitFont,
  },
  content: {
    height: windowHeight * 0.5,
  },
  homeFooter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  version: {
    position: 'absolute',
    right: 30,
    marginTop: 28,
  },
  versionText: {
    fontSize: 14,
    fontFamily: KanitFont,
    color: Color.gray,
    textAlign: 'right'
  },
}

export default HomeScreen;
