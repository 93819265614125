export const GET_AVAILABLE_VENUES_PENDING = "GET_AVAILABLE_VENUES_PENDING";
export const GET_AVAILABLE_VENUES_SUCCESS = "GET_AVAILABLE_VENUES_SUCCESS";
export const GET_AVAILABLE_VENUES_FAILED = "GET_AVAILABLE_VENUES_FAILED";
export const GET_AVAILBLE_DATES_PENDING = "GET_AVAILBLE_DATES_PENDING";
export const GET_AVAILBLE_DATES_SUCCESS = "GET_AVAILBLE_DATES_SUCCESS";
export const GET_AVAILBLE_DATES_FAILED = "GET_AVAILBLE_DATES_FAILED";
export const SET_BOOK_QUEUE = "SET_BOOK_QUEUE";
export const CREATE_APPOINTMENT_PENDING = 'CREATE_APPOINTMENT_PENDING';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILED = 'CREATE_APPOINTMENT_FAILED';
export const GET_APPOINTMENT_PENDING = 'GET_APPOINTMENT_PENDING';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILED = 'GET_APPOINTMENT_FAILED';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_BOOK_QUEUE = 'CLEAR_BOOK_QUEUE';
export const PRINT_APPOINTMENT_PENDING = 'PRINT_APPOINTMENT_PENDING';
export const PRINT_APPOINTMENT_SUCCESS = 'PRINT_APPOINTMENT_SUCCESS';
export const PRINT_APPOINTMENT_FAILED = 'PRINT_APPOINTMENT_FAILED';
export const GET_LIST_APPOINTMENT_PENDING = 'GET_LIST_APPOINTMENT_PENDING';
export const GET_LIST_APPOINTMENT_FAILED = 'GET_LIST_APPOINTMENT_FAILED';
export const GET_LIST_APPOINTMENT_SUCCESS = 'GET_LIST_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_PENDING = 'CANCEL_APPOINTMENT_PENDING';
export const CANCEL_APPOINTMENT_FAILED = 'CANCEL_APPOINTMENT_FAILED';
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';
export interface AppointmentState {
  appointment: Appointment;
  listAppointment: Array<ListAppointment>;
  bookQueue: BookQueue;
  venues: Array<Venues>;
  dates: DatesState;
  isGettingVenues: boolean;
  isGettingDate: boolean;
  isCreatingAppointment: boolean;
  isGettingAppointment: boolean;
  isPrintAppointment: boolean;
  isGettingListAppointment: boolean;
  isCancelAppointment: boolean;
  error: string;
  errorMessage: string;
}

export interface Appointment {
  id: string;
  appointment_no: string;
  url: string;
  venue: string;
  when: string;
  print_url: string;
  status: string;
}

export interface ListAppointment {
  id: string;
  appointment_no: string;
  url: string;
  venue: string;
  when: string;
  print_url: string;
  status: string;
}
export interface AppointmentRequest {
  date: string;
  time_slot_id: string;
}

export interface BookQueue {
  date: string;
  timeRange: string;
  venue: string;
}

export interface Venues {
  id: string;
  name: string;
}

export interface DatesState {
  [Key: string]: Array<DatesInfo>;
}
export interface DatesInfo {
  id: string;
  is_avail: boolean;
  name: string;
}

export interface AppError {
  message: string;
}

export interface GetAvailableVenuesPendingAction {
  type: typeof GET_AVAILABLE_VENUES_PENDING;
}

export interface GetAvailableVenuesSuccessAction {
  type: typeof GET_AVAILABLE_VENUES_SUCCESS;
  venues: Array<Venues>;
}

export interface GetAvailableVenuesFailedAction {
  type: typeof GET_AVAILABLE_VENUES_FAILED;
  error: string;
}

export interface GetAvailableDatesPendingAction {
  type: typeof GET_AVAILBLE_DATES_PENDING;
}

export interface GetAvailableDatesSuccessAction {
  type: typeof GET_AVAILBLE_DATES_SUCCESS;
  dates: DatesState;
}

export interface GetAvailableDatesFailedAction {
  type: typeof GET_AVAILBLE_DATES_FAILED;
  error: string;
}

export interface SetBookQueue {
  type: typeof SET_BOOK_QUEUE;
  bookQueue: BookQueue;
}

export interface CreateAppointmentPendingAction {
  type: typeof CREATE_APPOINTMENT_PENDING;
}

export interface CreateAppointmentSuccesAction {
  type: typeof CREATE_APPOINTMENT_SUCCESS;
  appointment: Appointment;
}

export interface CreateAppointmentFailedAction {
  type: typeof CREATE_APPOINTMENT_FAILED;
  error: string;
}

export interface GetAppointmentPendingAction {
  type: typeof GET_APPOINTMENT_PENDING;
}

export interface GetAppointmentSuccesAction {
  type: typeof GET_APPOINTMENT_SUCCESS;
  appointment: Appointment;
}

export interface GetAppointmentFailedAction {
  type: typeof GET_APPOINTMENT_FAILED;
  error: string;
}

export interface PrintAppointmentPendingAction {
  type: typeof PRINT_APPOINTMENT_PENDING;
}

export interface PrintAppointmentSuccesAction {
  type: typeof PRINT_APPOINTMENT_SUCCESS;
  appointment: Appointment;
}

export interface PrintAppointmentFailedAction {
  type: typeof PRINT_APPOINTMENT_FAILED;
  error: string;
}


export interface ClaerErrorAction {
  type: typeof CLEAR_ERROR;
  error: string;
}

export interface ClaerBookQueueAction {
  type: typeof CLEAR_BOOK_QUEUE;
  bookQueue: BookQueue;
}

export interface GetListAppointmentPendingAction {
  type: typeof GET_LIST_APPOINTMENT_PENDING;
}

export interface GetListAppointmentSuccesAction {
  type: typeof GET_LIST_APPOINTMENT_SUCCESS;
  listAppointment: Array<ListAppointment>;
}

export interface GetListAppointmentFailedAction {
  type: typeof GET_LIST_APPOINTMENT_FAILED;
  error: string;
}

export interface CancelAppointmentPendingAction {
  type: typeof CANCEL_APPOINTMENT_PENDING;
}

export interface CancelAppointmentSuccesAction {
  type: typeof CANCEL_APPOINTMENT_SUCCESS;
  appointment: Appointment;
}

export interface  CancelAppointmentFailedAction {
  type: typeof CANCEL_APPOINTMENT_FAILED;
  errorMessage: string;
}

export type AppointmentActionType =
  GetAvailableVenuesPendingAction | GetAvailableVenuesSuccessAction | GetAvailableVenuesFailedAction | GetAvailableDatesPendingAction |
  GetAvailableDatesSuccessAction | GetAvailableDatesFailedAction | SetBookQueue | CreateAppointmentPendingAction | CreateAppointmentSuccesAction |
  CreateAppointmentFailedAction | GetAppointmentPendingAction | GetAppointmentSuccesAction | GetAppointmentFailedAction | ClaerErrorAction | ClaerBookQueueAction | 
  PrintAppointmentSuccesAction | PrintAppointmentPendingAction | PrintAppointmentFailedAction | GetListAppointmentPendingAction | GetListAppointmentSuccesAction |
  GetListAppointmentFailedAction | CancelAppointmentPendingAction | CancelAppointmentSuccesAction | CancelAppointmentFailedAction;
