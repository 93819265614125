export const GET_PROFILE_PENDING = "GET_PROFILE_PENDING";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export interface DataProfile {
  firstname: string;
  lastname: string;
  address: string;
  district_id: string;
  province_id: string;
  subdistrict_id: string;
  citizen_id: string;
  passport_id: string;
}

export interface AppError {
  message: string;
}

export interface ProfileState {
  isGettingProfile: boolean,
  dataProfile: DataProfile;
}

export interface GetProfilePendingAction {
  type: typeof GET_PROFILE_PENDING;
}

export interface GetProfileSuccessAction {
  type: typeof GET_PROFILE_SUCCESS;
  dataProfile: DataProfile;
}

export interface GetProfileFailAction {
  type: typeof GET_PROFILE_FAIL;
  error: string;
}

export type ProfileActionType =
  | GetProfilePendingAction
  | GetProfileSuccessAction
  | GetProfileFailAction;
