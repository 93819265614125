import axios, { AxiosError } from "axios";
import { AppThunk } from "..";
import { API, url } from "../../constants";
import {
  AddressInfo,
  AdministrativeActionType,
  GET_DISTRICTS_FAILED,
  GET_DISTRICTS_PENDING,
  GET_DISTRICTS_SUCCESS,
  GET_PROVINCES_FAILED,
  GET_PROVINCES_PENDING,
  GET_PROVINCES_SUCCESS,
  GET_SUBDISTRICTS_FAILED,
  GET_SUBDISTRICTS_PENDING,
  GET_SUBDISTRICTS_SUCCESS,
} from "./types";

const getProvincesPending = (): AdministrativeActionType => {
  return {
    type: GET_PROVINCES_PENDING,
  };
};

const getProvincesSuccess = (provinces: Array<AddressInfo>): AdministrativeActionType => {
  return {
    type: GET_PROVINCES_SUCCESS,
    provinces: provinces,
  };
};

const getProvincesFailed = (error: string): AdministrativeActionType => {
  return {
    type: GET_PROVINCES_FAILED,
    error: error,
  };
};

export const getProvinces = (): AppThunk => {
    return (dispatch: any) => {
    dispatch(getProvincesPending());
    axios
      .get(url(API.provinces))
      .then((response) => {
        dispatch(getProvincesSuccess(response.data));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getProvincesFailed(message));
      });
  };
};

const getDistrictsPending = (): AdministrativeActionType => {
  return {
    type: GET_DISTRICTS_PENDING,
  };
};

const getDistrictsSuccess = (districts: Array<AddressInfo>): AdministrativeActionType => {
  return {
    type: GET_DISTRICTS_SUCCESS,
    districts: districts,
  };
};

const getDistrictsFailed = (error: string): AdministrativeActionType => {
  return {
    type: GET_DISTRICTS_FAILED,
    error: error,
  };
};

export const getDistricts = (id: number): AppThunk => {
    return (dispatch: any) => {
    dispatch(getDistrictsPending());
    axios
      .get(url(API.districts), { params: { province_id: id } })
      .then((response) => {
        dispatch(getDistrictsSuccess(response.data));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getDistrictsFailed(message));
      });
  };
};

const getSubdistrictsPending = (): AdministrativeActionType => {
  return {
    type: GET_SUBDISTRICTS_PENDING,
  };
};

const getSubdistrictsSuccess = (subdistricts: Array<AddressInfo>): AdministrativeActionType => {
  return {
    type: GET_SUBDISTRICTS_SUCCESS,
    subdistricts: subdistricts,
  };
};

const getSubdistrictsFailed = (error: string): AdministrativeActionType => {
  return {
    type: GET_SUBDISTRICTS_FAILED,
    error: error,
  };
};

export const getSubdistricts = (id: number): AppThunk => {
    return (dispatch: any) => {
    dispatch(getSubdistrictsPending());
    axios
      .get(url(API.subdistricts), { params: { district_id: id } })
      .then((response) => {
        dispatch(getSubdistrictsSuccess(response.data));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getSubdistrictsFailed(message));
      });
  };
};
