import {
  LIST_QUESTIONNAIRE_FAIL,
  LIST_QUESTIONNAIRE_PENDING,
  LIST_QUESTIONNAIRE_SUCCESS,
  QuestionnaireActionType,
  QuestionnaireState,
  GET_RISK_PENDING,
  GET_RISK_SUCCESS,
  GET_RISK_FAIL,
  CLEAR_DATA_RISK,
} from './types';

const initialState: QuestionnaireState = {
  isGettingQuestionnaires: false,
  questionnaires: [],
  isGettingRisk: false,
  dataRisk: {
    id: '',
    message: '',
    risk: '',
    venue: '',
  },
  errorMessage: '',
};

export const questionnaireReducer = (
  state = initialState,
  action: QuestionnaireActionType
): QuestionnaireState => {
  switch (action.type) {
    case LIST_QUESTIONNAIRE_PENDING:
      return {
        ...state,
        isGettingQuestionnaires: true,
        errorMessage: '',
      };
    case LIST_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        isGettingQuestionnaires: false,
        questionnaires: action.questionnaires,
        errorMessage: '',
      };
    case LIST_QUESTIONNAIRE_FAIL:
      return {
        ...state,
        isGettingQuestionnaires: false,
        errorMessage: action.errorMessage,
      };
    case GET_RISK_PENDING:
      return {
        ...state,
        isGettingRisk: true,
        errorMessage: '',
      };
    case GET_RISK_SUCCESS:
      return {
        ...state,
        isGettingRisk: false,
        dataRisk: action.dataRisk,
        errorMessage: '',
      };
    case GET_RISK_FAIL:
      return {
        ...state,
        isGettingRisk: false,
        errorMessage: action.errorMessage,
      };
    case CLEAR_DATA_RISK:
      return {
        ...state,
        dataRisk: {
          id: '',
          message: '',
          risk: '',
          venue: ''
        }
      };
    default:
      return state;
  }
};
