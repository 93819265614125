import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { Layout } from 'antd';
import { Color, KanitFont } from '../constants';
import { StylesDictionary } from '../utils/utils';
import BG from '../assets/images/bg.svg';

const { Header, Content, Footer } = Layout;

const windowHeight = window.innerHeight;

interface Props {
  title?: string;
  layoutStyles?: CSSProperties;
  contentStyles?: CSSProperties;
  hiddenHeader?: boolean;
  footer?: ReactNode;
  hasBackground?: boolean;
}

const ScreenLayout: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <Layout style={{ ...styles.layout, ...props.layoutStyles }}>
      <Header style={styles.header} hidden={props.hiddenHeader}>
        {props.title}
      </Header>
      <Content style={styles.content}>{props.children}</Content>
      <Footer style={styles.footer}></Footer>
    </Layout>
  );
};

const VirusScreenLayout: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <Layout style={{ ...styles.layout, ...props.layoutStyles }}>
      <Header style={styles.header} hidden={props.hiddenHeader}>
        {props.title}
      </Header>
      <Content style={{ ...styles.content, height: windowHeight * 0.75, ...props.contentStyles }}>
        {props.children}
        {props.hasBackground && (
          <div style={styles.bg}>
            <img src={BG} alt="virus-brackground" />
          </div>
        )}
      </Content>
      <Footer style={styles.footer}>{props.footer}</Footer>
    </Layout>
  );
};

export { ScreenLayout, VirusScreenLayout };

const styles: StylesDictionary = {
  layout: {
    backgroundColor: 'transparent',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    backgroundColor: Color.bermuda,
    fontFamily: KanitFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    color: Color.white,
    padding: '0px 10px',
  },
  content: {
    padding: '20px 30px',
    fontFamily: KanitFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    backgroundColor: 'transparent',
    overflow: 'auto',
  },
  footer: {
    backgroundColor: 'transparent',
    padding: '20px 30px',
  },
  bg: {
    zIndex: -1,
    position: 'absolute',
    top: 89,
    right: 0,
    bottom: 0,
  },
};
