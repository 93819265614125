import axios, { AxiosError } from "axios";
import { AppThunk } from "..";
import {
  AppError,
  ResultActionType,
  GET_RESULTS_FAIL,
  GET_RESULTS_PENDING,
  GET_RESULTS_SUCCESS,
  DataResult,
} from "./types";

import { API, customUrl } from "../../constants";

const getResultPending = (): ResultActionType => {
  return {
    type: GET_RESULTS_PENDING,
  };
};

const getResultSuccess = (dataResult: DataResult): ResultActionType => {
  return {
    type: GET_RESULTS_SUCCESS,
    dataResult: dataResult,
  };
};

const getResultFailed = (error: string): ResultActionType => {
  return {
    type: GET_RESULTS_FAIL,
    error: error,
  };
};

export const getLatestResult = (): AppThunk => {
  return (dispatch: any) => {
    dispatch(getResultPending());
    axios
      .get(customUrl(API.infected, 'my/'))
      .then((response) => {
        dispatch(getResultSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getResultFailed(message));
      });
  };
};

export const getResult = (id: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(getResultPending());
    axios
      .get(customUrl(API.infected, `${id}/`))
      .then((response) => {
        dispatch(getResultSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getResultFailed(message));
      });
  };
};
