
export const GET_RESULTS_PENDING = "GET_RESULTS_PENDING";
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS";
export const GET_RESULTS_FAIL = "GET_RESULTS_FAIL";

export interface ResultsState {
  isGettingResults: boolean;
  dataResult: DataResult;
  error: string;
}

export interface DataResult {
  file_url: string;
  is_infected: boolean;
  result_url: string;
  appointment_id: string;
}

export interface AppError {
  message: string;
}

export interface GetResultPendingAction {
  type: typeof GET_RESULTS_PENDING;
}

export interface GetResultSuccessAction {
  type: typeof GET_RESULTS_SUCCESS;
  dataResult: DataResult;
}

export interface GetResultFailAction {
  type: typeof GET_RESULTS_FAIL;
  error: string;
}

export type ResultActionType =
  | GetResultPendingAction
  | GetResultSuccessAction
  | GetResultFailAction;
