import { CSSProperties, useEffect, useRef } from "react";

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export interface StylesDictionary {
  [Key: string]: CSSProperties;
}

export const colorWithOpacity = (color: string, opacity: number) => { 
  opacity = Math.max(Math.min(opacity, 1), 0);
  const hex = (Math.round(opacity * 255) + 0x10000).toString(16).substr(-2); // 0x10000 แก้ปัญหาของ opacity ที่มีเป็น 0 แล้วจะ return 0 แทนที่จะเป็น 00
  return color + hex
}

export const validateCitizenID = (id: any) => {
  if (id.substring(0, 1) === 0) return { message: 'เลขบัตรประชาชนไม่ถูกต้อง', value: false };
  if (id.length !== 13) return { message: 'กรอกเลขบัตรประชาชนให้ครบถ้วน', value: false };
  let i
  let sum
  for (i = 0, sum = 0; i < 12; i++)
    sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - sum % 11) % 10 !== parseFloat(id.charAt(12))) return { message: 'เลขบัตรประชาชนไม่ถูกต้อง', value: false };
  return { value: true };
}

export const validatePatientID = (id: any) => {
  if (id.length < 4) return { message: 'กรอกเลข HN อย่างน้อย 4 ตัวอักษรขึ้นไป', value: false };
  return { value: true };
}

export const validatePassportID = (id: any) => {
  if (id.length < 7 || id.length > 13) return { message: 'เลขยืนยันตัวตนอื่นไม่ถูกต้อง', value: false };
  const regex = new RegExp(/(^[A-Z]{0,2})[0-9]+$/);
  if (!regex.test(id)) return { message: 'เลขยืนยันตัวตนอื่นไม่ถูกต้อง', value: false }
  return { value: true }
}

export const validatePassportLogin = (id: any) => {
  if (id.length < 7 || id.length > 13) return { message: 'กรอกเลข Passport อย่างน้อย 8 ตัวอักษรขึ้นไป', value: false };
  return { value: true }
}
