import axios, { AxiosError } from "axios";
import { AppThunk } from "..";
import { API, url } from "../../constants";
import {
  AppError,
  DataProfile,
  GET_PROFILE_FAIL,
  GET_PROFILE_PENDING,
  GET_PROFILE_SUCCESS,
  ProfileActionType,
} from "./types";

const getProfilePending = (): ProfileActionType => {
  return {
    type: GET_PROFILE_PENDING,
  };
};

const getProfileSuccess = (dataProfile: DataProfile): ProfileActionType => {
  return {
    type: GET_PROFILE_SUCCESS,
    dataProfile: dataProfile,
  };
};

const getProfileFailed = (error: string): ProfileActionType => {
  return {
    type: GET_PROFILE_FAIL,
    error: error,
  };
};

export const getProfile = (): AppThunk => {
  return (dispatch: any) => {
    dispatch(getProfilePending());
    axios
      .get(url(API.get_profiles))
      .then((response) => {
        dispatch(getProfileSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getProfileFailed(message));
      });
  };
};
