import { AppThunk } from "..";
import {
  GET_FEATURE_FLAG_PENDING,
  GET_FEATURE_FLAG_SUCCESS,
  FeatureFlagActionType,
} from "./types";
import { UnleashClient } from 'unleash-proxy-client';

const getFeatureFlagPending = (): FeatureFlagActionType => {
  return {
    type: GET_FEATURE_FLAG_PENDING,
  };
};

const getFeatureFlagSuccess = (riskAssessmentEnabled: boolean, myTestResultEnabled: boolean): FeatureFlagActionType => {
  return {
    type: GET_FEATURE_FLAG_SUCCESS,
    riskAssessmentEnabled: riskAssessmentEnabled,
    myTestResultEnabled: myTestResultEnabled,
  };
};

let unleash: UnleashClient | null;

export const loadFeatureFlag = (): AppThunk => {
  return (dispatch: any) => {
    dispatch(getFeatureFlagPending());
    unleash = new UnleashClient({
      url: process.env.REACT_APP_UNLEASH_URL || '',
      appName: process.env.REACT_APP_UNLEASH_APP_NAME || '',
      clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY || '',
    });
    unleash.on('update', () => {
      if(unleash){
        dispatch(
          getFeatureFlagSuccess(unleash.isEnabled('risk_assessment'), unleash.isEnabled('my_test_result'))
          );
      }
    })
    unleash.start();
  };
};
