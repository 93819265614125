import {
    ResultActionType,
    GET_RESULTS_PENDING,
    GET_RESULTS_SUCCESS,
    GET_RESULTS_FAIL,
    ResultsState,
} from './types';

const initialState: ResultsState = {
    isGettingResults: false,
    dataResult: {
        file_url: '',
        is_infected: false,
        result_url: '',
        appointment_id: '',
    },
    error: '',
}

export const resultsReducer = (state = initialState, action: ResultActionType): ResultsState => {
    switch (action.type) {
        case GET_RESULTS_PENDING:
            return {
                ...state,
                isGettingResults: true,
                error: '',
            }
        case GET_RESULTS_SUCCESS:
            return {
                ...state,
                isGettingResults: false,
                dataResult: action.dataResult,
            }
        case GET_RESULTS_FAIL:
            return {
                ...state,
                isGettingResults: false,
                error: action.error,
            }
        default:
            return state
    }
}
