import axios, { AxiosError } from "axios";
import { AppThunk } from "..";
import { API, url } from "../../constants";
import {
  Error,
  RegisterInfo,
  REGISTER_FAILED,
  REGISTER_PENDING,
  REGISTER_SUCCESS,
  SET_FORM,
} from "./types";

export const setForm = (form: RegisterInfo) => {
  return {
    type: SET_FORM,
    registerInfo: form,
  }
}

const registerPending = () => {
  return {
      type: REGISTER_PENDING,
  };
}

const registerSuccess = () => {
  return {
      type: REGISTER_SUCCESS,
  };
}

const registerFailed = (error: string, listErrors?: Error[]) => {
  return {
      type: REGISTER_FAILED,
      error,
      listErrors,
  };
}

export const register = (dataForm: RegisterInfo): AppThunk => {
  return (dispatch: any) => {
    dispatch(registerPending());
    axios
      .post(url(API.profiles), dataForm)
      .then((response) => {
        dispatch(registerSuccess());
      })
      .catch((error: AxiosError) => {
        const message = error.response?.data.message || error.message;
        const listErrors = error.response?.data.errors;
        dispatch(registerFailed(message, listErrors));
      });
  };
}
