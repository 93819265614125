import {
    RegisterActionType,
    RegisterState,
    REGISTER_FAILED,
    REGISTER_PENDING,
    REGISTER_SUCCESS,
    SET_FORM,
} from './types';

const initialState: RegisterState = {
    errorMessage: '', 
    isRegistering: false,
    registerInfo: {
        address: '',
        district_id: '',
        firstname: '',
        lastname: '',
        citizen_card_image:'',
        province_id: '',
        subdistrict_id: '',
        phone_no: '',

    },
}

export const registerReducer = (state = initialState, action: RegisterActionType): RegisterState => {
    switch (action.type) {
        case SET_FORM:
            return {
                ...state,
                registerInfo: action.registerInfo
            };
        case REGISTER_PENDING:
            return {
                ...state,
                isRegistering: true,
                errorMessage: '',
                listErrors: undefined,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isRegistering: false,
            };
        case REGISTER_FAILED:
            return {
                ...state,
                errorMessage: action.error,
                listErrors: action.listErrors,
                isRegistering: false,
            };
        default:
            return state;
    }
}
