export const GET_FEATURE_FLAG_PENDING = "GET_FEATURE_FLAG_PENDING";
export const GET_FEATURE_FLAG_SUCCESS = "GET_FEATURE_FLAG_SUCCESS";

export interface AppError {
  message: string;
}

export interface FeatureFlagState {
  isLoading: boolean,
  riskAssessmentEnabled: boolean;
  myTestResultEnabled: boolean;
}

export interface GetFeatureFlagPendingAction {
  type: typeof GET_FEATURE_FLAG_PENDING;
}

export interface GetFeatureFlagSuccessAction {
  type: typeof GET_FEATURE_FLAG_SUCCESS;
  riskAssessmentEnabled: boolean;
  myTestResultEnabled: boolean;
}

export type FeatureFlagActionType =
  | GetFeatureFlagPendingAction
  | GetFeatureFlagSuccessAction;
