import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout';
import { PrimaryButton } from '../../components/button';
import { useHistory } from 'react-router-dom';
import { StylesDictionary, validateCitizenID, validatePassportLogin, validatePatientID} from '../../utils/utils';
import { Alert, Form, Input, Spin, Radio } from 'antd';
import NumberFormat from 'react-number-format';
import { KanitFont, Color } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import './login-with-basic.css';
import { RootState } from '../../services';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { loginWithBirthday } from '../../services/auth/actions';
interface Props {
  title?: string;
  text?: string;
  textAdvice?: string;
  go?: string;
}

const LoginWithBasicInfo: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const errorMessage = useSelector((state: RootState) => state.auth.errorMessage);
  const token = useSelector((state: RootState) => state.auth.token);
  const [dateTime, setDateTime] = useState('วันเกิด');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [radio, setRadio] = useState('1');

  const dispatch = useDispatch();

  useEffect(()=>{
    if(token){
    history.replace('/result/my/');
    }
  },[token])

  const getIdentityID = (e: any, field: string) => {
    if (field === 'citizen_id') {
      form.setFieldsValue({ citizen_id: e.value });
    } else if (field === 'passport_id') {
      form.setFieldsValue({ passport_id: String(e.target.value).toUpperCase() });
    } else {
      form.setFieldsValue({ hn: e.value });
    }
    form.validateFields(['citizen_id', 'passport_id', 'hn'])
  }

  const onSubmit = async () => {
    const values = await form.validateFields();
    dispatch(loginWithBirthday(values));
      setLoading(true);
  };

  const optionsWithDisabled = [
    { label: 'เลขที่บัตรประชาชน', value: '1' },
    { label: 'Passport', value: '2' },
    { label: 'HN', value: '3' },
  ];

  const footer = () => {
    return (
      <div style={styles.buttonBox}>
        <PrimaryButton
          style={styles.button}
          title="ยืนยัน"
          onClick={onSubmit}
          disabled={isError}
        />
      </div>
    );
  };

  const onChange = (e: any) => {
    setRadio(
      e.target.value,
    );
    form.setFieldsValue({
      citizen_id: undefined,
      passport_id: undefined,
      hn: undefined
    });
  };

  const isLoading = !errorMessage ? loading: !loading

  return (
    <Spin spinning={isLoading}>
      <VirusScreenLayout title={props.title} footer={footer()}>
        <div style={styles.container}>
          <h3 style={styles.title}>กรุณาใส่ข้อมูลให้ครบถ้วน</h3>
          <Radio.Group
              options={optionsWithDisabled}
              onChange={onChange}
              optionType="button"
              value={radio}
            />
          <Form
            layout="vertical"
            name="basic"
            form={form}
            style={styles.textArea}
            className="login-result-page"
            onFieldsChange={(fieldsValue) =>
              setIsError(
                !!fieldsValue.filter(({ errors }) => errors!.length).length
              )
            }
          >
            {radio === '1' ?
              <Form.Item
                label="เลขที่บัตรประชาชน"
                name="citizen_id"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value) {
                        const validate = validateCitizenID(value);
                        if (validate.value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(validate.message);
                      }
                    },
                  }),
                ]}
              >
                <Input hidden />
                <NumberFormat
                  onValueChange={(e: any) => getIdentityID(e, 'citizen_id')}
                  className="ant-input"
                  style={styles.input}
                  placeholder="เลขที่บัตรประชาชน"
                  format="#-####-#####-##-#"
                  mask="_"
                />
              </Form.Item>
              : (
                radio === '2' ?
                  <Form.Item
                    label="Passsport"
                    name="passport_id"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value) {
                            const validate = validatePassportLogin(value);
                            if (validate.value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(validate.message);
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      onChange={(e: any) => getIdentityID(e, 'passport_id')}
                      style={styles.input}
                      placeholder="Passsport"
                      maxLength={13}
                    />
                  </Form.Item>
                  :
                  <Form.Item
                    label="HN"
                    name="hn"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value) {
                            const validate = validatePatientID(value);
                            if (validate.value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(validate.message);
                          }
                        },
                      }),
                    ]}
                  >
                    <Input hidden />
                    <NumberFormat
                      onValueChange={(e: any) => getIdentityID(e, 'hn')}
                      className="ant-input"
                      style={styles.input}
                      placeholder="HN"
                    />
                  </Form.Item>
              )
            }
            
            <Form.Item
              label="วันเกิด"
              name="dob"
              rules={[
                { message: 'กรุณาเลือกวันเกิด' },
              ]}
            >
              <Input hidden />
              <div
                className="ant-input"
                onClick={() => setIsOpen(!isOpen)}
                style={{ ...styles.input, }}
              >
                {dateTime}
              </div>
              <div
                hidden={!isOpen}
                style={styles.postionCalendar}
              >
                <Calendar
                  onChange={(value: any) => {
                    setIsOpen(false);
                    setDateTime(moment(value).add(543, 'year').format('DD/MM/YYYY'))
                    form.setFieldsValue({ dob: moment(value).format('YYYY-MM-DD') });
                    form.validateFields(['dob'])
                  }}
                  locale="th"
                />
              </div>
            </Form.Item>
          </Form>
          <p> {props.text} </p>
          {props.textAdvice && <p style={styles.h5}> {props.textAdvice} </p>}
        </div>
        <div style={styles.styleError}>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              style={styles.errorMessage}
              showIcon
            />
          )}
        </div>
      </VirusScreenLayout>
    </Spin>
  );
};

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    textAlign: 'center',
  },
  textArea: {
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
  },
  button: {
    height: 40,
    width: '100%',
  },
  input: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.pictonBlue,
    borderRadius: 4,
    boxSizing: 'border-box',
    width: '100%',
    color: Color.pictonBlue,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: Color.white,
  },
  title: {
    fontFamily: KanitFont,
    fontSize: 14,
  },
  h5: {
    fontSize: 16,
    color: Color.chetwodeBlue,
    textAlign: 'center',
  },
  styleError: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'center',
  },
  postionCalendar: {
    zIndex: 1,
    position: 'absolute',
    maxWidth: '70%' ,
  }
};

export default LoginWithBasicInfo;
