export const GET_PROVINCES_PENDING = "GET_PROVINCES_PENDING";
export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_PROVINCES_FAILED = "GET_PROVINCES_FAILED";
export const GET_DISTRICTS_PENDING = "GET_DISTRICTS_PENDING";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const GET_DISTRICTS_FAILED = "GET_DISTRICTS_FAILED";
export const GET_SUBDISTRICTS_PENDING = "GET_SUBDISTRICTS_PENDING";
export const GET_SUBDISTRICTS_SUCCESS = "GET_SUBDISTRICTS_SUCCESS";
export const GET_SUBDISTRICTS_FAILED = "GET_SUBDISTRICTS_FAILED";
export const SET_FORM = "SET_FORM";
export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export interface RegisterState {
  errorMessage: string,
  listErrors?: RegisterError, 
  registerInfo: RegisterInfo,
  isRegistering: boolean,
}

export interface RegisterInfo {
  address: string;
  citizen_id?: string;
  district_id: string;
  firstname: string;
  lastname: string;
  passport_id?: string;
  province_id: string;
  subdistrict_id: string;
  phone_no: string;
  citizen_card_image: string;
}

export interface RegisterError {
  address?: string[];
  citizen_id?: string[];
  district_id?: string[];
  firstname?: string[];
  lastname?: string[];
  passport_id?: string[];
  province_id?: string[];
  subdistrict_id?: string[];
}

export interface Error {
  errorMessage: string,
}

export interface setFormAction {
  type: typeof SET_FORM,
  registerInfo: RegisterInfo,
}

export interface RegisterPendingAction {
  type: typeof REGISTER_PENDING,
}

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS,
}

export interface RegisterFailedAction {
  type: typeof REGISTER_FAILED,
  error: string,
  listErrors?: RegisterError,
}

export type RegisterActionType =
  | setFormAction
  | RegisterPendingAction
  | RegisterSuccessAction
  | RegisterFailedAction;
