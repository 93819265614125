import { Select } from 'antd';
const { Option } = Select;

interface GenerateOptionProps {
    id: string;
    name: string;
    disabled?: boolean;
}

const GenerateOption = (data: GenerateOptionProps[], key: string) => {
    return data.map((value) => {
        return <Option value={String(value.id)} key={`${key}-${value.id}`} disabled={value.disabled ? value.disabled : false}>{value.name}</Option>;
    });
};

export { GenerateOption };
