import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout';
import { PrimaryButton } from '../../components/button';
import { Form, Input, Select, Spin, Upload, Button, Checkbox, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Color, KanitFont } from '../../constants';
import NumberFormat from 'react-number-format';
import { RootState } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterInfo } from '../../services/register/types';
import { setForm } from '../../services/register/actions';
import './register.css';
import { useHistory } from 'react-router-dom';
import { StylesDictionary, validateCitizenID } from '../../utils/utils';
import { getDistricts, getProvinces, getSubdistricts } from '../../services/administrative/actions';
import { GenerateOption } from '../../components/generate-option';
import { getProfile } from '../../services/profile/actions';

interface Props {
  title?: string;
}

interface DropdownProps {
  registerInfo: RegisterInfo;
  form: any;
}

const Dropdown: React.FC<DropdownProps> = ({ form, registerInfo }) => {
  const dispatch = useDispatch();
  const address = useSelector((state: RootState) => state.administrative.address);
  const [provinces, setProvinces] = useState<JSX.Element[]>();
  const [districts, setDistricts] = useState<JSX.Element[]>();
  const [subdistricts, setSubdistricts] = useState<JSX.Element[]>();
  const [isAgreement, setIsAgreement] = useState();
  useEffect(() => {
    dispatch(getProvinces());
  }, [])

  useEffect(() => {
    setProvinces(GenerateOption(address.provinces, 'provinces'));
  }, [address.provinces]);

  useEffect(() => {
    setDistricts(GenerateOption(address.districts, 'districts'));
  }, [address.districts]);

  useEffect(() => {
    setSubdistricts(GenerateOption(address.subdistricts, 'subdistricts'));
  }, [address.subdistricts]);

  const selectedProvinces = (e: any) => {
    dispatch(getDistricts(e));
    dispatch(getSubdistricts(0));
    form.setFieldsValue({ district_id: undefined, subdistrict_id: undefined, });
  }

  const selectedDistricts = (e: any) => {
    dispatch(getSubdistricts(e));
    form.setFieldsValue({ subdistrict_id: undefined, });
  }

  function onChange(e: any) {
      setIsAgreement(e.target.checked)
  }

  return (
    <div>
      <Form.Item
        {...(registerInfo?.province_id ? { initialValue: registerInfo.province_id } : undefined)}
        label="จังหวัด"
        name="province_id"
        rules={[
          { required: true, message: 'กรุณาเลือกจังหวัด' }
        ]}
      >
        <Select
          showSearch
          bordered={false}
          style={{ ...styles.input, paddingLeft: 0, paddingRight: 0, }}
          placeholder="จังหวัด..."
          optionFilterProp="children"
          onChange={selectedProvinces}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {provinces}
        </Select>
      </Form.Item>
      <Form.Item
        {...(registerInfo?.district_id ? { initialValue: registerInfo.district_id } : undefined)}
        label="อำเภอ/เขต"
        name="district_id"
        rules={[
          { required: true, message: 'กรุณาเลือกอำเภอ/เขต' }
        ]}
      >
        <Select
          showSearch
          bordered={false}
          style={{ ...styles.input, paddingLeft: 0, paddingRight: 0, }}
          placeholder="อำเภอ/เขต..."
          optionFilterProp="children"
          disabled={districts?.length === 0}
          onChange={selectedDistricts}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {districts}
        </Select>
      </Form.Item>
      <Form.Item
        {...(registerInfo?.subdistrict_id ? { initialValue: registerInfo.subdistrict_id } : undefined)}
        label="ตำบล/แขวง"
        name="subdistrict_id"
        rules={[
          { required: true, message: 'กรุณาเลือกตำบล/แขวง' }
        ]}
      >
        <Select
          showSearch
          bordered={false}
          style={{ ...styles.input, paddingLeft: 0, paddingRight: 0, }}
          placeholder="ตำบล/แขวง..."
          optionFilterProp="children"
          disabled={subdistricts?.length === 0}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {subdistricts}
        </Select>
      </Form.Item>
      <Form.Item
        name="checkbox"
        rules={[
          { required: !isAgreement, message: 'กรุณากดยืนยันข้อตลง' }
        ]}
      >
        <Checkbox onChange={onChange} >
          ฉันได้อ่านเเละยอมรับข้อตกลง <a href={process.env.REACT_APP_AGREEMENT_URL} rel='noreferrer' target='_blank'>อ่านข้อตกลง </a>
        </Checkbox>
      </Form.Item>
    </div >
  )
}

const RegisterScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const registerInfo = useSelector((state: RootState) => state.register.registerInfo);
  const [form] = Form.useForm();
  const history = useHistory();
  const phone_no = useSelector((state: RootState) => state.auth.phone_no);
  const [isError, setIsError] = useState(false);
  const isGettingProfile = useSelector((state: RootState) => state.profile.isGettingProfile);
  const dataProfile = useSelector((state: RootState) => state.profile.dataProfile);
  const [isCardImg, setIsCardImg] = useState(false);
  useEffect(() => {
    if (dataProfile.firstname) {
      history.push('/appointment');
    }
  }, [dataProfile])

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  const onSubmit = async () => {
    try {
      const values: RegisterInfo = await form.validateFields();
      const form_add_phone = {
        ...values,
        citizen_id: values.citizen_id ? values.citizen_id : undefined,
        passport_id: values.passport_id ? values.passport_id : undefined,
        phone_no: phone_no ?? registerInfo?.phone_no,
        citizen_card_image: values.citizen_card_image ?? registerInfo?.citizen_card_image,
      }
      dispatch(setForm(form_add_phone));
      history.push('/confirm_register');
    } catch (errorInfo) {

    };
  }

  const getIdentityID = (e: any, field: string) => {
    if (field === 'citizen_id') {
      form.setFieldsValue({ citizen_id: e.value });
    } else {
      form.setFieldsValue({ passport_id: String(e.target.value).toUpperCase() });
    }
    form.validateFields(['citizen_id', 'passport_id'])
  }

  const footer = () => {
    return (
      <div style={styles.buttonBox}>
        <PrimaryButton
          style={styles.button}
          title="บันทึกข้อมูล"
          onClick={onSubmit}
          disabled={isError}
        />
      </div>
    );
  }

  const selectedCitizenCardImg = (e: any) => {
    form.setFieldsValue({ citizen_card_image: e.substr(23) });
  }

  const onRemove = (e: any) => {
    setIsCardImg(!isCardImg);
  }
  const onChange = (info: any) => {
    if (info.file.status !== 'uploading') {
      message.success('successfully');
      getBase64(info.file.originFileObj)
      setIsCardImg(!isCardImg);
    }
  }

  function getBase64(file: any | undefined) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(selectedCitizenCardImg(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  return (
    <Spin spinning={isGettingProfile}>
      <VirusScreenLayout title={props.title} footer={footer()} hasBackground={true}>
        {!isGettingProfile && (
          <div className="register-page" style={styles.container}>
            <div style={styles.containArea}>
              <h3 style={styles.title}>กรุณากรอกประวัติส่วนตัว</h3>
              <Form
                layout="vertical"
                name="basic"
                form={form}
                className='auth-page'
                onFieldsChange={(fieldsValue) => setIsError(!!fieldsValue.filter(({ errors }) => errors!.length).length)}
              >
                <Form.Item
                  label="ชื่อ"
                  name="firstname"
                  initialValue={registerInfo?.firstname ?? undefined}
                  rules={[
                    { required: true, message: 'กรุณากรอกชื่อ' },
                    { pattern: /(^[a-zA-Z]+$|^[ก-ฮเแไใโ][ก-๏]+$)/, message: 'กรอกชื่อผิดรูปแบบ' },
                  ]}
                >
                  <Input
                    style={styles.input}
                    placeholder="ชื่อ"
                  />
                </Form.Item>
                <Form.Item
                  label="นามสกุล"
                  name="lastname"
                  initialValue={registerInfo?.lastname ?? undefined}
                  rules={[
                    { required: true, message: 'กรุณากรอกนามสกุล' },
                    { pattern: /(^[a-zA-Z]+$|^[ก-ฮเแไใโ][ก-๏]+$)/, message: 'กรอกชื่อผิดรูปแบบ' },
                  ]}
                >
                  <Input
                    style={styles.input}
                    placeholder="นามสกุล"
                  />
                </Form.Item>
                <Form.Item
                  label="เลขบัตรประชาชน"
                  name="citizen_id"
                  initialValue={registerInfo?.citizen_id ?? undefined}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value) {
                          const validate = validateCitizenID(value);
                          if (getFieldValue('passport_id')) {
                            return Promise.reject('กรุณากรอกเลขบัตรประชาชน');
                          }
                          if (validate.value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(validate.message);
                        } else {
                          if (getFieldValue('passport_id')) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('กรุณากรอกอย่างใดอย่างหนึ่ง');
                          }
                        }
                      },
                    }),
                  ]}
                >
                  <Input hidden />
                  <NumberFormat
                    defaultValue={registerInfo?.citizen_id ?? undefined}
                    onValueChange={(e: any) => getIdentityID(e, 'citizen_id')}
                    className="ant-input"
                    style={styles.input}
                    placeholder="เลขที่บัตรประชาชน"
                    format="#-####-#####-##-#"
                    mask="_"
                  />
                </Form.Item>
                <Form.Item
                  label="รูปบัตรประชาชน"
                  name="citizen_card_image"
                  initialValue={registerInfo?.citizen_card_image ?? undefined}
                  rules={[
                    { required: !isCardImg, message: 'กรุณาเเนบรูปบัตรประชาชน' }
                  ]}
                >
                  <Upload
                    listType="picture-card"
                    onRemove={(e) => onRemove(e)}
                    onChange={(e) => onChange(e)}
                  >
                    <Button icon={<UploadOutlined />}>เลือกรูป</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label="ที่อยู่ (ขอให้ใส่ที่อยู่ปัจจุบัน)"
                  name="address"
                  initialValue={registerInfo?.address ?? undefined}
                  rules={[
                    { required: true, message: 'กรุณากรอกที่อยู่' }
                  ]}
                >
                  <Input
                    style={styles.input}
                    placeholder="ที่อยู่"
                  />
                </Form.Item>
                <Dropdown registerInfo={registerInfo} form={form} />
              </Form>
            </div>
          </div>
        )}
      </VirusScreenLayout>
    </Spin>
  );
}

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'center',
  },
  containArea: {
    flex: 1,
    flexGrow: 1,
    width: '100%',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 16,
  },
  button: {
    height: 40,
    width: '100%',
  },
  title: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: Color.eclipse,
  },
  text: {
    fontFamily: KanitFont,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: Color.eclipse,
  },
  input: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'left',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.pictonBlue,
    borderRadius: 4,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    color: Color.pictonBlue,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: Color.white,
  },
  error: {
    color: Color.darkPink,
  },
}

export default RegisterScreen;
