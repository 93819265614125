import React from 'react';
import { useSelector } from 'react-redux';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
} from 'react-router-dom';
import {
    AppointmentScreen,
    ConfirmAppointmentScreen,
    ShowAllAppointmentScreen,
    ShowAppointmentScreen,
} from '../scenes/appointment';
import { PageNotFound } from '../scenes/page-not-found/page-not-found';
import { RootState } from '../services';

const AppointmentNavigator: React.FC = () => {
    const { path } = useRouteMatch();
    const appointment = useSelector((state: RootState) => state.appointment.appointment);

    return (
        <Switch>
            <Route exact path={`${path}/`} render={({ location }) =>
                appointment.status === "CF"
                    ?
                    <Redirect
                        to={{
                            pathname: '/appointment/my/',
                            state: { from: location.pathname },
                        }} />
                    :
                    <AppointmentScreen title="ทำการนัดหมาย" />
            } />

            <Route exact path={`${path}/confirm_appointment`}>
                <ConfirmAppointmentScreen title="ยืนยันเวลานัดหมาย" />
            </Route>
            <Route exact path={`${path}/my/`}>
                <ShowAppointmentScreen title="ดูการนัดหมาย" />
            </Route>
            <Route exact path={`${path}/:appointmentId/`}>
                <ShowAppointmentScreen title="ดูการนัดหมาย" />
            </Route>
            <Route exact path={`${path}/my/confirm_appointment/show_all_appointment`}>
                <ShowAllAppointmentScreen title="การนัดหมายทั้งหมด" />
            </Route>
            <Route>
                <PageNotFound />
            </Route>
        </Switch>
    );
}

export default AppointmentNavigator;
