import axios, { AxiosError } from "axios";
import { AppThunk } from "..";
import { API, customUrl, url } from "../../constants";
import { 
  AppError,
  Appointment,
  AppointmentActionType,
  AppointmentRequest,
  BookQueue,
  CREATE_APPOINTMENT_FAILED,
  CREATE_APPOINTMENT_PENDING,
  CREATE_APPOINTMENT_SUCCESS,
  DatesState,
  GET_APPOINTMENT_FAILED,
  GET_APPOINTMENT_PENDING,
  GET_APPOINTMENT_SUCCESS,
  GET_AVAILABLE_VENUES_FAILED, 
  GET_AVAILABLE_VENUES_PENDING, 
  GET_AVAILABLE_VENUES_SUCCESS,
  GET_AVAILBLE_DATES_FAILED,
  GET_AVAILBLE_DATES_PENDING,
  GET_AVAILBLE_DATES_SUCCESS,
  GET_LIST_APPOINTMENT_PENDING,
  GET_LIST_APPOINTMENT_FAILED,
  GET_LIST_APPOINTMENT_SUCCESS,
  PRINT_APPOINTMENT_PENDING,
  PRINT_APPOINTMENT_SUCCESS,
  PRINT_APPOINTMENT_FAILED,
  CANCEL_APPOINTMENT_PENDING,
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_APPOINTMENT_FAILED,
  SET_BOOK_QUEUE,
  CLEAR_ERROR,
  CLEAR_BOOK_QUEUE,
  Venues,
 } from "./types";

const getAvailableVenuesPending = (): AppointmentActionType => {
  return {
    type: GET_AVAILABLE_VENUES_PENDING,
  };
};

const getAvailableVenuesSuccess = (venues: Array<Venues>): AppointmentActionType => {
  return {
    type: GET_AVAILABLE_VENUES_SUCCESS,
    venues: venues,
  };
};

const getAvailableVenuesFailed = (error: string): AppointmentActionType => {
  return {
    type: GET_AVAILABLE_VENUES_FAILED,
    error: error,
  };
};

export const getAvailableVenues = (evaluation_id?: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(getAvailableVenuesPending());
    const params = evaluation_id ? { evaluation_id } : {}
    axios
      .get(url(API.venues), { params })
      .then((response) => {
        dispatch(getAvailableVenuesSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getAvailableVenuesFailed(message));
      });
  };
};

const getAvailableDateesPending = (): AppointmentActionType => {
  return {
    type: GET_AVAILBLE_DATES_PENDING,
  };
};

const getAvailableDateesSuccess = (dates: DatesState): AppointmentActionType => {
  return {
    type: GET_AVAILBLE_DATES_SUCCESS,
    dates: dates,
  };
};

const getAvailableDateesFailed = (error: string): AppointmentActionType => {
  return {
    type: GET_AVAILBLE_DATES_FAILED,
    error: error,
  };
};

export const getAvailableDates = (venue_id: string, evaluation_id : string): AppThunk => {
  return (dispatch: any) => {
    dispatch(getAvailableDateesPending());
    axios
      .get(customUrl(API.venues, `${venue_id}${API.avail_date}`), { params: { evaluation_id } })
      .then((response) => {
        dispatch(getAvailableDateesSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getAvailableDateesFailed(message));
      })
  }
}

export const setBookQueue = (bookQueue: BookQueue) => {
  return {
    type: SET_BOOK_QUEUE,
    bookQueue: bookQueue,
  }
}

const createAppointmentPending = (): AppointmentActionType => {
  return {
    type: CREATE_APPOINTMENT_PENDING,
  };
};

const createAppointmentSuccess = (appointment: Appointment): AppointmentActionType => {
  return {
    type: CREATE_APPOINTMENT_SUCCESS,
    appointment: appointment,
  };
};

const createAppointmentFailed = (error: string): AppointmentActionType => {
  return {
    type: CREATE_APPOINTMENT_FAILED,
    error: error,
  };
};

export const createAppointment = (data: AppointmentRequest): AppThunk => {
  return (dispatch: any) => {
    dispatch(createAppointmentPending());
    axios
      .post(url(API.appointments), data)
      .then((response: any) => {
        dispatch(createAppointmentSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(createAppointmentFailed(message));
      })
  }
}

const getAppointmentPending = (): AppointmentActionType => {
  return {
    type: GET_APPOINTMENT_PENDING,
  };
};

const getAppointmentSuccess = (appointment: any): AppointmentActionType => {
  return {
    type: GET_APPOINTMENT_SUCCESS,
    appointment: appointment,
  };
};

const getAppointmentFailed = (error: string): AppointmentActionType => {
  return {
    type: GET_APPOINTMENT_FAILED,
    error: error,
  };
};

const printAppointmentPending = (): AppointmentActionType => {
  return {
    type: PRINT_APPOINTMENT_PENDING,
  };
};

const printAppointmentSuccess = (appointment: any): AppointmentActionType => {
  return {
    type: PRINT_APPOINTMENT_SUCCESS,
    appointment: appointment,
  };
};

const printAppointmentFailed = (error: string): AppointmentActionType => {
  return {
    type: PRINT_APPOINTMENT_FAILED,
    error: error,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
}

export const clearBookQueue = () => {
  return {
    type: CLEAR_BOOK_QUEUE,
  };
}

const getListAppointmentPending = (): AppointmentActionType => {
  return {
    type: GET_LIST_APPOINTMENT_PENDING,
  };
};

const getListAppointmentSuccess = (listAppointment: any): AppointmentActionType => {
  return {
    type: GET_LIST_APPOINTMENT_SUCCESS,
    listAppointment: listAppointment,
  };
};

const getListAppointmentFailed = (error: string): AppointmentActionType => {
  return {
    type: GET_LIST_APPOINTMENT_FAILED,
    error: error,
  };
};

const cancelAppointmentPending = (): AppointmentActionType => {
  return {
    type: CANCEL_APPOINTMENT_PENDING,
  };
};

const cancelAppointmentSuccess = (appointment: any): AppointmentActionType => {
  return {
    type: CANCEL_APPOINTMENT_SUCCESS,
    appointment: appointment,
  };
};

const cancelAppointmentFailed = (errorMessage: string): AppointmentActionType => {
  return {
    type: CANCEL_APPOINTMENT_FAILED,
    errorMessage: errorMessage,
  };
};

export const getLatestAppointment = (token?: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(getAppointmentPending());
   const config = token ? ({
           headers: {'Authorization': `Token ${token}` }
    }) : {}
    axios
      .get(customUrl(API.appointments, 'my/'), config)
      .then((response: any) => {
        dispatch(getAppointmentSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getAppointmentFailed(message));
      })
  }
}

export const getAppointment = (id: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(getAppointmentPending());
    axios
      .get(customUrl(API.appointments, `${id}/`))
      .then((response: any) => {
        dispatch(getAppointmentSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getAppointmentFailed(message));
      })
  }
}

export const printAppointment = (id: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(printAppointmentPending());
    axios
      .post(customUrl(API.appointments, `${id}/print/`))
      .then((response: any) => {
        dispatch(printAppointmentSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(printAppointmentFailed(message));
      })
  }
}

export const getListAppointment = (): AppThunk => {
  return (dispatch: any) => {
    dispatch(getListAppointmentPending());
    axios
      .get(url(API.appointments))
      .then((response: any) => {
        dispatch(getListAppointmentSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(getListAppointmentFailed(message));
      })
  }
} 

export const cancelAppointment = (id: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(cancelAppointmentPending());
    axios
      .post(customUrl(API.appointments, `${id}/cancel/`))
      .then((response: any) => {
        dispatch(cancelAppointmentSuccess(response.data));
      })
      .catch((error: AxiosError<AppError>) => {
        const message = error.response?.data.message || error.message;
        dispatch(cancelAppointmentFailed(message));
      })
  }
}
