import React, { useEffect, useState } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
    PrimaryButton,
    ResultsButton,
} from '../../components/button';
import { StylesDictionary, usePrevious } from '../../utils/utils';
import { QRCode } from "react-qr-svg";
import { Color, KanitFont } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestResult, getResult } from '../../services/results/actions';
import { RootState } from '../../services';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Result, Spin } from 'antd';
import Negative from '../../assets/images/negative.jpg';
import Positive from '../../assets/images/positive.jpg';
interface Props {
    title?: string;
}

interface Params {
    resultId: string;
}

const ResultScreen: React.FC<Props> = (props) => {
    const infected = useSelector((state: RootState) => state.results.dataResult);
    const dispatch = useDispatch();
    const { resultId } = useParams<Params>();
    const history = useHistory();
    const isGettingResults = useSelector((state: RootState) => state.results.isGettingResults);
    const errorMessage = useSelector((state: RootState) => state.results.error);
    const prevIsGetResults = usePrevious(isGettingResults);
    const [isCallSuccess, setIsCallSuccess] = useState(false);
    const { state } = useLocation();    
    const param: any = state;
    
    useEffect(() => {
        if (!isGettingResults && prevIsGetResults) {
            setIsCallSuccess(true);
        } else {
            setIsCallSuccess(false);
        }
      }, [isGettingResults]);

    useEffect(() => {
        if (!resultId) {
            dispatch(getLatestResult());
        } else {
            dispatch(getResult(resultId));
        }
    }, []);

    const header = infected.is_infected
        ? 'กรุณาติดต่อกลับคลินิกอบอุ่นด่วน ที่เบอร์ 0869595695'
        : 'ผลเป็นลบ โดย ชุดตรวจ Covid-19 Ag Test'
    
    const title = param?.title ?? props.title;

    const footer = () => {
            return (
                <div style={styles.buttonBox}>
                    <PrimaryButton title='ย้อนกลับ' style={styles.button} onClick={() => history.push('/')} />
                </div>
            )
    }

    const goAppointment = () => {
        history.push(`/appointment/${infected.appointment_id}`);
    }

    const isNoResult = isCallSuccess && !infected?.result_url;
    const isShowError = isNoResult || !!errorMessage;
    const statusMessage = errorMessage ? 'warning' : 'info';
    const resultTitle = errorMessage ? errorMessage : 'ไม่มีข้อมูลผลการตรวจในขณะนี้';
    const margin = infected.file_url == undefined ?  80 :  0;

    return ( 
        <Spin spinning={isGettingResults}>
            <VirusScreenLayout title={title} hasBackground={!isShowError} footer={footer()}>
                {isCallSuccess &&
                    <div style={styles.container}>
                        {isShowError
                            ? (<>
                                <Result
                                    status={statusMessage}
                                    title={resultTitle}
                                />
                            </>)
                            : (
                                <>
                                    <div style={infected.is_infected ? styles.textResultPositive : styles.textResultNegative}>
                                        {header}
                                    </div>
                                    <div style={styles.img}>
                                        <img src={infected.is_infected ? Positive : Negative } style={{width: "100%", height: 314.891}}/>
                                    </div>
                                    { 
                                        infected.file_url != undefined &&
                                        <ResultsButton title='ดูผลเต็ม (PDF)' style={styles.button} onClick={() => window.open(infected.file_url)} />
                                    }
                                    <p style={{...styles.text, marginTop: margin}}> {'คุณสามารถเข้ามาดูผลการตรวจได้ตลอดเวลาที่'} </p>
                                    <div style={{ alignSelf: 'center' }}>
                                        <a style={styles.link} href={infected.result_url} rel='noreferrer' target='_blank' >{infected.result_url}</a>
                                    </div>
                                    <p style={styles.text}> {'หรือสแกนที่ QR Code ด้านล่าง'} </p>
                                    <div style={styles.styleQrcode}>
                                        <QRCode
                                            bgColor={Color.white}
                                            fgColor={Color.black}
                                            style={{ width: 150 }}
                                            value={infected.result_url}
                                        />
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <a onClick={goAppointment}>{'ดูการนัดหมาย'}</a>
                                    </div>
                                </>
                            )}
                    </div>
                }
            </VirusScreenLayout>
        </Spin>
    );
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
    },
    link: {
        fontFamily: KanitFont,
        fontSize: 16,
        textAlign: 'center'
    },
    bg: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 16,
    },
    button: {
        marginTop: 8,
        height: 40,
        width: '100%',
    },
    textResultPositive: {
        alignSelf: 'center',
        color: Color.darkPink
    },
    textResultNegative: {
        alignSelf: 'center',
        color: Color.bermuda
    },
    text: {
        fontFamily: KanitFont,
        fontSize: 16,
        alignSelf: 'center',
        marginBottom: -1,
        zIndex: 1,
        color: Color.eclipse,
    },
    styleQrcode: {
        backgroundColor: Color.white,
        alignSelf: 'center',
        padding: 16,
        zIndex: 1,
        marginTop: 16,
        marginBottom: 16,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Color.eclipse,
        boxSizing: 'border-box',
        borderRadius: 4,
    },
    img:{
        alignSelf: 'center',
    }
}

export default ResultScreen;
