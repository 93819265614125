import {
    AdministrativeActionType,
    AdministrativeState,
    GET_DISTRICTS_FAILED,
    GET_DISTRICTS_PENDING,
    GET_DISTRICTS_SUCCESS,
    GET_PROVINCES_FAILED,
    GET_PROVINCES_PENDING,
    GET_PROVINCES_SUCCESS,
    GET_SUBDISTRICTS_FAILED,
    GET_SUBDISTRICTS_PENDING,
    GET_SUBDISTRICTS_SUCCESS,
} from './types';

const initialState: AdministrativeState = {
    errorMessage: '',
    address: {
        districts: [],
        provinces: [],
        subdistricts: [],
    },
    isGettingDistricts: false,
    isGettingProvinces: false,
    isGettingSubdistricts: false,
}

export const administrativeReducer = (state = initialState, action: AdministrativeActionType): AdministrativeState => {
    switch (action.type) {
        case GET_PROVINCES_PENDING:
            return {
                ...state,
                address: {
                    ...state.address,
                    provinces: [],
                },
                errorMessage: '',
                isGettingProvinces: true,
            };
        case GET_PROVINCES_SUCCESS:
            return {
                ...state,
                address: {
                    ...state.address,
                    provinces: action.provinces
                },
                isGettingProvinces: false,
            };
        case GET_PROVINCES_FAILED:
            return {
                ...state,
                errorMessage: action.error,
                isGettingProvinces: false,
            };
        case GET_DISTRICTS_PENDING:
            return {
                ...state,
                address: {
                    ...state.address,
                    districts: [],
                },
                isGettingDistricts: true,
            };
        case GET_DISTRICTS_SUCCESS:
            return {
                ...state,
                address: {
                    ...state.address,
                    districts: action.districts
                },
                isGettingDistricts: false,
            };
        case GET_DISTRICTS_FAILED:
            return {
                ...state,
                errorMessage: action.error,
                isGettingDistricts: false,
            };
        case GET_SUBDISTRICTS_PENDING:
            return {
                ...state,
                address: {
                    ...state.address,
                    subdistricts: [],
                },
                isGettingSubdistricts: true,
            };
        case GET_SUBDISTRICTS_SUCCESS:
            return {
                ...state,
                address: {
                    ...state.address,
                    subdistricts: action.subdistricts
                },
                isGettingSubdistricts: false,
            };
        case GET_SUBDISTRICTS_FAILED:
            return {
                ...state,
                errorMessage: action.error,
                isGettingSubdistricts: false,
            };
        default:
            return state;
    }
}
