import {
    FeatureFlagActionType,
    GET_FEATURE_FLAG_PENDING,
    GET_FEATURE_FLAG_SUCCESS,
    FeatureFlagState,
} from './types';

const initialState: FeatureFlagState = {
    isLoading: true,
    riskAssessmentEnabled: false,
    myTestResultEnabled: false,
}

export const featureFlagReducer = (state = initialState, action: FeatureFlagActionType): FeatureFlagState => {
    switch (action.type) {
        case GET_FEATURE_FLAG_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_FEATURE_FLAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                riskAssessmentEnabled: action.riskAssessmentEnabled,
                myTestResultEnabled: action.myTestResultEnabled,
            };
        default:
            return state;
    }
}
