import {
    ProfileActionType,
    GET_PROFILE_PENDING,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAIL,
    ProfileState,
} from './types';

const initialState: ProfileState = {
    isGettingProfile: true,
    dataProfile: {
        firstname: '',
        lastname: '',
        address: '',
        district_id: '',
        province_id:'',
        subdistrict_id: '',
        citizen_id: '',
        passport_id: '',
    },
}

export const profileReducer = (state = initialState, action: ProfileActionType): ProfileState => {
    switch (action.type) {
        case GET_PROFILE_PENDING:
            return {
                ...state,
                isGettingProfile: true,
            };
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                isGettingProfile: false,
                dataProfile: action.dataProfile,
            };
        case GET_PROFILE_FAIL:
            return {
                ...state,
                isGettingProfile: false,
            };
        default:
            return state;
    }
}
