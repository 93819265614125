import React from 'react';
import { Button } from 'antd';
import {
  Color,
  KanitFont,
} from '../constants';
import { colorWithOpacity } from '../utils/utils';

interface Props {
  title?: string;
  style?: any;
  onClick?: (event: any) => void;
  disabled?: boolean;
}

const PrimaryButton: React.FC<Props> = (props) => {
  const disabledButton = props.disabled
    ? { background: `linear-gradient(90deg, ${colorWithOpacity(Color.pictonBlue, 0.5)} 0%, ${colorWithOpacity(Color.bermuda, 0.5)} 100%)`, }
    : {};
  return (
    <Button
      {...props}
      style={{ ...props.style, ...styles.button, ...styles.primaryButton, ...disabledButton }}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
}

const ResultsButton: React.FC<Props> = (props) => {
  return (
    <Button
      {...props}
      style={{ ...props.style, ...styles.button, ...styles.pesultsButton }}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
}

const SecondaryButton: React.FC<Props> = (props) => {
  return (
    <Button
      {...props}
      type="ghost"
      style={{ ...props.style, ...styles.button, ...styles.sencondaryButton }}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
}

const styles = {
  button: {
    borderRadius: '4px',
    borderWidth: 0,
    fontFamily: KanitFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  primaryButton: {
    color: Color.white,
    background: `linear-gradient(90deg, ${Color.pictonBlue} 0%, ${Color.bermuda} 100%)`,
  },
  pesultsButton: {
    color: Color.white,
    background: Color.chetwodeBlue,
    zIndex: 1,
    marginTop: 24,
    width:150,
    alignSelf: 'center',
    marginBottom: 56,
  },
  sencondaryButton: {
    color: Color.pictonBlue,
    backgroundColor: 'transparent',
    border: `1px solid ${Color.pictonBlue}`,
    borderColor: Color.pictonBlue,
  }
};

export {
  PrimaryButton,
  SecondaryButton,
  ResultsButton,
};
