import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { PageNotFound } from '../scenes/page-not-found/page-not-found';
import QuestionnaireScreen from '../scenes/questionnaires';
import RiskScreen from '../scenes/risk-assessment';
import { RootState } from '../services';

const QuestionnaireNavigator: React.FC = () => {
  let { path } = useRouteMatch();
  const dataRisk = useSelector(
    (state: RootState) => state.questionnaire.dataRisk
  );
  return (
    <Switch>
      <Route exact path={`${path}/risk`}>
        <RiskScreen title="ผลการประเมินความเสี่ยง" />
      </Route>
      <Route
        render={() =>
          dataRisk.risk.length === 0 ? (
            <Route exact path={`${path}`}>
              <QuestionnaireScreen title="แบบประเมินความเสี่ยง" />
            </Route>
          ) : (
            <Redirect
              to={{
                pathname: `${path}/risk`,
              }}
            />
          )
        }
      />
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default QuestionnaireNavigator;
