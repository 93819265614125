import React, { useEffect, useState, } from 'react';
import { VirusScreenLayout } from '../../components/layout'
import {
  PrimaryButton,
} from '../../components/button';
import Canlender from '../../assets/images/canlender.svg';
import { Color, KanitFont, YearDifference } from '../../constants';
import { Alert, Form, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableDates, getAvailableVenues, setBookQueue } from '../../services/appointment/actions';
import { RootState } from '../../services';
import { BookQueue, Venues } from '../../services/appointment/types';
import './appointment.css';
import moment from 'moment';
import { GenerateOption } from '../../components/generate-option';
import { StylesDictionary } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
interface Props {
  title?: string;
}

const AppointmentScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [venueOptions, setVenueOptions] = useState<JSX.Element[]>([]);
  const [dateOptions, setDateOptions] = useState<JSX.Element[]>([]);
  const [timeRangeOptions, setTimeRangeOptions] = useState<JSX.Element[]>([]);
  const [venueSelected, setVenueSelected] = useState<Venues>();
  const [dateSelected, setDateSelected] = useState<string>();
  const history = useHistory();
  const venues = useSelector((state: RootState) => state.appointment.venues);
  const errorMessage = useSelector((state: RootState) => state.appointment.error);

  const dates = useSelector((state: RootState) => state.appointment.dates);
  const bookQueue = useSelector((state: RootState) => state.appointment.bookQueue);
  const isGettingVenues = useSelector((state: RootState) => state.appointment.isGettingVenues);
  const [isError, setIsError] = useState(false);
  const evaluationId = useSelector((state: RootState) => state.questionnaire.dataRisk.id);
  const riskAssessmentEnabled = useSelector((state: RootState) => state.featureFlag.riskAssessmentEnabled);
  
  const title = venueOptions?.length === 0
    ? `ไม่มีสถานที่ตรวจสำหรับท่าน`
    : (venueOptions?.length === 1
      ? `กรุณาเลือกเวลาที่จะเข้ารับการตรวจที่ ${venueSelected && venueSelected.name}`
      : `กรุณาเลือกโรงพยาบาลและเวลาที่จะเข้ารับการตรวจ`);

  const onSubmit = async () => {
    try {
      const values: BookQueue = await form.validateFields();
      dispatch(setBookQueue(values));
      history.push('/appointment/confirm_appointment', { venues, time: dates[values.date] });
    }catch {
    };
  }

  const onBack = () => {
    history.push('/');
  }

  const noPlace = venueOptions?.length === 0;
  
  useEffect(()=>{
    if(riskAssessmentEnabled && !evaluationId){
      history.push('/questionnaires');
    }
  },[])

  useEffect(() => {
    dispatch(getAvailableVenues(evaluationId));
    if (bookQueue.date) {
      setDateSelected(bookQueue.date)
    }
  }, []);

  useEffect(() => {
    if (venues) {
      setVenueOptions(GenerateOption(venues, 'venues'));
      if (venues.length === 1) {
        setVenueSelected(venues[0]);
        form.setFieldsValue({ venue: venues[0].id });
        form.validateFields(['venue'])
      }
    }
  }, [venues]);

  useEffect(() => {
    if (venueSelected) {
      dispatch(getAvailableDates(venueSelected.id, evaluationId));
    }
  }, [venueSelected]);

  useEffect(() => {
    if (dates) {
      const datesList = Object.getOwnPropertyNames(dates).map((value) => {
        return { id: value, name: moment(value).add(YearDifference, 'year').format('D MMMM YYYY') }
      })
      setDateOptions(GenerateOption(datesList, 'dates'));
    }
  }, [dates]);

  useEffect(() => {
    if (dateSelected) {
      const timeRange = [...dates[dateSelected]].map((value) => { return { ...value, disabled: !value.is_avail } });
      const timeCorrect = timeRange.find(value => value.id === bookQueue.timeRange);
      if(!timeCorrect?.is_avail) {
        form.setFieldsValue({ timeRange: undefined });
      }
      setTimeRangeOptions(GenerateOption(timeRange, 'time-range'));
    }
  }, [dateSelected,dates]);

  const footer = () => {
    return (
      <div style={styles.buttonBox}>
        {noPlace ? (
          <PrimaryButton
            style={styles.button}
            title="กลับหน้าเเรก"
            onClick={onBack}
            disabled={isError}
          />
        ) : (
          <PrimaryButton
            style={styles.button}
            title="ยืนยัน"
            onClick={onSubmit}
            disabled={isError}
          />
        )}
      </div>
    );
  }

  return (
    <VirusScreenLayout
      title={props.title}
      footer={footer()}
      hasBackground={true}
    >
      <Spin spinning={isGettingVenues}>
        <div style={styles.containArea} className="appointment-page">
          <div style={{ textAlign: 'center' }}>
            <img src={Canlender} alt="calender-icon" />
          </div>
          {noPlace && !isGettingVenues  ? (
            <h3 style={{ ...styles.title, color: 'red' , marginTop: 20 }}>{title}</h3>
          ) : (
            <>
              <h3 style={styles.title}>{title}</h3>
              <Form
                layout="vertical"
                name="basic"
                form={form}
                className="auth-page"
                onFieldsChange={(fieldsValue) =>
                  setIsError(
                    !!fieldsValue.filter(({ errors }) => errors!.length).length
                  )
                }
              >
                <Form.Item
                  {...(bookQueue.venue
                    ? { initialValue: bookQueue.venue }
                    : undefined)}
                  label="เลือกโรงพยาบาล"
                  name="venue"
                  rules={[{ required: true, message: 'กรุณาเลือกโรงพยาบาล' }]}
                  hidden={venueOptions?.length <= 1}
                >
                  <Select
                    showSearch
                    bordered={false}
                    style={{ ...styles.input, paddingLeft: 0, paddingRight: 0 }}
                    placeholder="เลือกโรงพยาบาล..."
                    optionFilterProp="children"
                    disabled={venueOptions?.length === 0}
                    onChange={(value: string) => {
                      form.setFieldsValue({
                        date: undefined,
                        timeRange: undefined,
                      });
                      setDateSelected('');
                      setTimeRangeOptions(GenerateOption([], 'time-range'));
                      setVenueSelected(venues.filter(venue => String(venue.id) === value).pop());
                    }}
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {venueOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...(bookQueue.date
                    ? { initialValue: bookQueue.date }
                    : undefined)}
                  label="เลือกวันที่"
                  name="date"
                  rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
                >
                  <Select
                    bordered={false}
                    style={{ ...styles.input, paddingLeft: 0, paddingRight: 0 }}
                    placeholder="เลือกวันที่..."
                    optionFilterProp="children"
                    disabled={dateOptions?.length === 0}
                    onChange={(value: string) => {
                      form.setFieldsValue({ timeRange: undefined });
                      setDateSelected(value);
                    }}
                  >
                    {dateOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...(bookQueue.timeRange
                    ? { initialValue: bookQueue.timeRange }
                    : undefined)}
                  label="เลือกเวลา"
                  name="timeRange"
                  rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                >
                  <Select
                    bordered={false}
                    style={{ ...styles.input, paddingLeft: 0, paddingRight: 0 }}
                    placeholder="เลือกเวลา..."
                    optionFilterProp="children"
                    disabled={timeRangeOptions?.length === 0}
                  >
                    {timeRangeOptions}
                  </Select>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
        <div style={styles.styleError}>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              style={styles.errorMessage}
              showIcon
            />
          )}
        </div>
      </Spin>
    </VirusScreenLayout>
  );
}

const styles: StylesDictionary = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'center',
  },
  containArea: {
    flex: 1,
    flexGrow: 1,
    width: '100%',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  button: {
    marginTop: 8,
    height: 40,
    width: '100%',
  },
  title: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: Color.eclipse,
    textAlign: 'center',
  },
  text: {
    fontFamily: KanitFont,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: Color.eclipse,
  },
  input: {
    fontFamily: KanitFont,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'left',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.pictonBlue,
    borderRadius: 4,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    color: Color.pictonBlue,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: Color.white,
  },
  styleError: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    alignItems: 'center',
  },
}

export default AppointmentScreen;


