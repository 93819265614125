import axios, { AxiosError } from "axios";
import { AppThunk } from "..";
import { API, url } from "../../constants";
import {
  LOGIN_FAILED,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  SENDING_OTP_FAILED,
  SENDING_OTP_PENDING,
  SENDING_OTP_SUCCESS,
  CLEAR_ERROR,
  SET_FORM,
} from "./types";

export const setForm = (phone_no: string) => {
  return {
    type: SET_FORM,
    phone_no,
  }
}

const loginPending = () => {
  return {
    type: LOGIN_PENDING,
  };
}

const loginSuccess = (token: string) => {
  return {
    type: LOGIN_SUCCESS,
    token,
  };
}

const loginFailed = (error: string) => {
  return {
    type: LOGIN_FAILED,
    error: error,
  };
}

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
}

const sendOTPPending = () => {
  return {
    type: SENDING_OTP_PENDING,
  };
}

const sendOTPSuccess = (otpToken: string) => {
  return {
    type: SENDING_OTP_SUCCESS,
    otpToken,
  };
}

const sendOTPFailed = (error: string) => {
  return {
    type: SENDING_OTP_FAILED,
    error: error,
  };
}

export const login = (phone_no: string, otp: string): AppThunk => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const form = {
      phone_no,
      otp,
      token: state.auth.otpToken,
    }
    dispatch(loginPending());
    axios
      .post(url(API.login), form)
      .then((response) => {
        dispatch(loginSuccess(response.data.token));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(loginFailed(message));
      });
  };
}

export const requestOTP = (phone_no: string): AppThunk => {
  return (dispatch: any) => {
    dispatch(sendOTPPending());
    axios
      .post(url(API.otp), { phone_no })
      .then((response) => {
        dispatch(sendOTPSuccess(response.data.token));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(sendOTPFailed(message));
      });
  };
}

export const loginWithBirthday = (form: any): AppThunk => {
  return (dispatch: any) => {
    dispatch(loginPending());
    axios
      .post(url(API.login_profile), form)
      .then((response) => {
        dispatch(loginSuccess(response.data.token));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(loginFailed(message));
      });
  };
}
export const loginWithCitizen = (form: any): AppThunk => {
  return (dispatch: any) => {
    dispatch(loginPending());
    axios
      .post(url(API.login_with_citizen), form)
      .then((response) => {
        dispatch(loginSuccess(response.data.token));
      })
      .catch((error: AxiosError<Error>) => {
        const message = error.response?.data.message || error.message;
        dispatch(loginFailed(message));
      });
  };
}
