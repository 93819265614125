import React, { ReactNode } from 'react';
import { Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { ResultStatusType } from 'antd/lib/result';
import { VirusScreenLayout } from '../../components/layout';
import { ResultsButton } from '../../components/button';
import { StylesDictionary } from '../questionnaires/questionnaire';

interface Props {
  title?: ReactNode;
  subTitle?: ReactNode;
  status?: ResultStatusType;
  icon?: ReactNode;
  extra?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const PageNotFound: React.FC<Props> = (props) => {
    const history = useHistory();

    const footer = () => {
      return (
        <div style={{textAlign: 'center'}}>
          <ResultsButton title='ย้อนกลับ' style={styles.button} onClick={() => history.push('/')} />
        </div>
      );
    }

    return (
    <VirusScreenLayout hiddenHeader={true} footer={footer()} >
      <Result
        status="404"
        title="404"
        subTitle="ไม่พบหน้าเว็บที่คุณค้นหา"
        {...props}
      />
    </VirusScreenLayout>
    );
  };

export { PageNotFound };

const styles: StylesDictionary = {
  button: {
      height: 40,
  },
}
